import endpoints from 'api/endpoints';
import { normalize } from 'normalizr';
import { topic } from 'schemas';
import Immutable from 'immutable';

export default ({ requestBody }) => ({
  url: endpoints.getTopicsUrl(),
  queryKey: `post${endpoints.getTopicsUrl()}`,
  transform: response => normalize(response.data, topic.schema).entities,
  transformResult: response => ({
    topics: normalize(response.data, topic.schema).result,
  }),
  body: requestBody,
  force: true,
  meta: {
    authToken: true,
  },
  options: {
    headers: {
      Accept: 'application/json',
    },
    method: 'POST',
  },
  update: {
    topic: (prevEntities = Immutable.Map(), nextEntities) =>
      prevEntities.mergeDeep(nextEntities),
  },
  updateResult: {
    topics: (prevResult = Immutable.List(), result) => prevResult.push(result),
  },
});
