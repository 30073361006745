import endpoints from 'api/endpoints';
import Immutable from 'immutable';

export default ({ body, id, topicId }) => ({
  url: endpoints.getFaqArticleByIdUrl(id),
  queryKey: `put${endpoints.getFaqArticleByIdUrl()}`,
  transform: response => ({ topicFaq: response.data }),
  body,
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: 'application/json',
    },
    method: 'PUT',
  },
  update: {
    topicFaq: (prevEntities = Immutable.Map(), nextEntities) => prevEntities.update(topicId, topic => {
      const foundArticleIndex = topic.get('articles').findIndex(article => article.get('id') === id);
      return topic.updateIn(['articles', foundArticleIndex], () => nextEntities);
    }),
  },
});
