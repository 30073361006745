import getTerms from './getTerms';
import postTerm from './postTerm';
import putTerm from './putTerm';
import deleteTerms from './deleteTerms';
import deleteFullTerms from './deleteFullTerms';

export default {
  getTerms,
  postTerm,
  putTerm,
  deleteTerms,
  deleteFullTerms,
};
