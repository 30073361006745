import React from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';

import { workWithFiles } from 'helpers';
import {getToken} from "helpers/token";
import endpoints from "api/endpoints";

import './style.scss';


const { resizeImageFile, dataUrlToFile } = workWithFiles;

const ImageField = ({
  input: { value },
  meta: {
    touched,
    error,
  },
  uploadImage,
  label,
}) => (
  <div className="image-picker">
    <input
      id="file"
      type="file"
      onChange={uploadImage}
      className="image-picker__input"
    />
    {/* eslint-disable-next-line jsx-a11y/label-has-for */}
    <label htmlFor="file">
      {`Choose a ${label}`}
    </label>
    {
      value && <img
        src={value}
        height="200"
        alt="icon"
        className="image-picker__image"
      />
    }
    {touched && error && <span className='field-error'>{error}</span>}
  </div>
);

ImageField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  uploadImage: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.any.isRequired,
};

export default compose(
  withHandlers({
    uploadFile: ({ input: { onChange } }) => async (file) => {

      const formData = new FormData();
      formData.append('file', file);

      const response = await fetch(endpoints.getUploadingBucketUrl('news'), {
        body: formData,
        method: 'POST',
        headers: {
          Authorization: getToken(),
        },
      });

      const data = await response.json();

      onChange(`${endpoints.getFileBucketUrl('news')}/${data.urls[0]}`);
    },
  }),
  withHandlers({
    uploadImage: ({
      uploadFile,
      maxWidth,
      maxHeight,
    }) => (e) => {
      const file = e.target.files[0];

      if (maxWidth && maxHeight) {
        resizeImageFile(file, maxWidth, maxHeight, (resizedImg) => {
          uploadFile(dataUrlToFile(resizedImg, file.name.split('.')[0]));
        });
      } else {
        uploadFile(file);
      }
    },
  }),
)(ImageField);
