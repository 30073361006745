import AuthForm from './AuthForm';
import FaqForm from './FaqForm';
import FaqArticleForm from './FaqArticleForm';
import AddStoryForm from './AddStoryForm';
import NewsForm from './NewsForm';
import BoardsForm from './BoardsForm';
import TopicsForm from './TopicsForm';
import PostsForm from './PostsForm';
import TermsForm from './TermsForm';

export default {
  AuthForm,
  FaqForm,
  FaqArticleForm,
  AddStoryForm,
  NewsForm,
  BoardsForm,
  TopicsForm,
  PostsForm,
  TermsForm,
};
