import endpoints from 'api/endpoints';
import { normalize } from 'normalizr';
import { news } from 'schemas';
import Immutable from 'immutable';

export default requestParams => ({
  url: endpoints.getNewsUrl(requestParams),
  queryKey: endpoints.getNewsUrl(),
  transform: response =>
    normalize(response.data, news.schemasArray).entities,
  transformResult: response => ({
    news: normalize(response.data, news.schemasArray).result,
    newsMeta: response.meta,
  }),
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: 'application/json',
    },
    method: 'GET',
  },
  update: {
    newsItem: (prevNewsItem = Immutable.Map(), nextNewsItem) =>
      prevNewsItem.mergeDeep(nextNewsItem),
  },
  updateResult: {
    news: (_, result) => result,
    newsMeta: (prevResult, result) => result,
  },
});
