import postCreateStory from './postCreateStory';
import getStories from './getStories';
import deleteStory from './deleteStory';
import putStory from './putStory';

export default {
  postCreateStory,
  getStories,
  deleteStory,
  putStory,
};
