import endpoints from 'api/endpoints';
import { Map } from 'immutable';

export default () => ({
  url: endpoints.getTerms(),
  transform: response => response.data,
  transformResult: response => ({
    terms: response.data,
  }),
  meta: {
    authToken: true,
  },
  force: true,
  queryKey: endpoints.getTerms(),
  options: {
    headers: {
        Accept: 'application/json',
    },
    method: 'GET',
  },
  update: {
    term: (prevEntities = Map(), nextEntities) =>
      prevEntities.mergeDeep(nextEntities),
  },
  updateResult: {
    terms: (prevResult, result) => result,
  },
});
