import endpoints from 'api/endpoints';
import Immutable from 'immutable';

export default ({ body, topicId }) => ({
  url: endpoints.getFaqArticles(),
  queryKey: `post${endpoints.getFaqArticles()}`,
  transform: response => ({ topicFaq: response.data }),
  body,
  force: true,
  meta: {
    authToken: true,
  },
  options: {
    headers: {
      Accept: 'application/json',
    },
    method: 'POST',
  },
  update: {
    topicFaq: (prevEntities = Immutable.Map(), nextEntities) => prevEntities.updateIn([topicId, 'articles'], prevArticles => {
      return prevArticles.push(nextEntities);
    }),
  },
});
