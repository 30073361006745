import getFaqTopics from './getFaqTopics';
import postTopic from './postTopic';
import putTopic from './putTopic';
import deleteTopic from './deleteTopic';
import postArticle from './postArticle';
import putArticle from './putArticle';
import deleteArticle from './deleteArticle';
import getFaqArticle from './getFaqArticle';

export default {
  postTopic,
  putTopic,
  deleteTopic,
  getFaqTopics,
  postArticle,
  putArticle,
  deleteArticle,
  getFaqArticle,
};
