import { normalize } from 'normalizr';
import endpoints from 'api/endpoints';
import { user } from 'schemas';
import Immutable from 'immutable';
import { token } from 'helpers';
import _ from 'lodash';

export default ({ requestBody }) => ({
  url: endpoints.getLoginUrl(),
  transform: (response) => {
    const authToken = _.get(response, 'data.token', '');
    const accessRights = _.get(response, 'data.accessRights');
    if (authToken && accessRights === 1022) {
      token.setToken(authToken);
    }
    if (response.data.message !== '2fa required' && accessRights === 1022) {
      return normalize(response.data, user.schema).entities;
    }
    return ({});
  },
  transformResult: (response) => {
    if (response.data.message !== '2fa required') {
      return ({
        user: normalize(response.data, user.schema).result,
      });
    }
    return ({});
  },
  queryKey: endpoints.getLoginUrl(),
  body: requestBody,
  force: true,
  meta: {},
  options: {
    headers: {
      Accept: 'application/json',
    },
    method: 'POST',
  },
  update: {
    user: (_, nextUser) => nextUser || Immutable.Map(),
  },
  updateResult: {
    user: (_, result) => result || '',
  },
});
