import { createSelector } from 'reselect';
import { getEntities, getResults } from 'reducers';
import Immutable from 'immutable';
import { denormalize } from 'normalizr';
import { topicFaq, article } from 'schemas';

export const getFaqTopics = createSelector(
  state => getResults(state).get('topicsFaq', Immutable.List()),
  state => getEntities(state),
  (result, entities) =>
    denormalize(
      result,
      topicFaq.schemasArray,
      entities,
    ),
);

export const getArticle = createSelector(
  state => getResults(state).get('article', Immutable.Map()),
  state => getEntities(state),
  (result, entities) =>
    denormalize(
      result,
      article.schema,
      entities,
    ),
);
