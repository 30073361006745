const resizeImage = (image, maxWidth, maxHeight, type) => {
  const canvas = document.createElement('canvas');

  let { width, height } = image;

  if (width > height) {
    if (width > maxWidth) {
      height = Math.round(height * maxWidth / width);
      width = maxWidth;
    }
  } else if (height > maxHeight) {
    width = Math.round(width * maxHeight / height);
    height = maxHeight;
  }

  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext('2d');
  ctx.drawImage(image, 0, 0, width, height);
  return canvas.toDataURL(type, 1);
};

export default (file, maxWidth, maxHeight, fn) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = (event) => {
    const dataUrl = event.target.result;

    const image = new Image();
    image.src = dataUrl;
    image.onload = () => {
      const resizedDataUrl = resizeImage(image, maxWidth, maxHeight, file.type);
      fn(resizedDataUrl);
    };
  };
};
