import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import endpoints from '../api/endpoints';

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'ru',
    fallbackLng: 'ru',
    preload: ['en', 'ru'],
    ns: ['translation'],
    defaultNS: 'translation',
    backend: {
      loadPath: `${endpoints.getTranslationsUrl()}/{{lng}}/{{ns}}.json`,
      addPath: `${endpoints.getTranslationsUrl()}/add/{{lng}}/{{ns}}`,
    },
  });

export default i18next;
