import endpoints from 'api/endpoints';

export default id => ({
  url: endpoints.getSinglePostUrl(id),
  queryKey: `delete${endpoints.getSinglePostUrl()}`,
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: 'application/json',
    },
    method: 'DELETE',
  },
  update: {
    post: prevResult => prevResult.filter(item => item.get('_id') !== id),
  },
  updateResult: {
    posts: prevResult => prevResult.filter(item => item !== id),
  },
});
