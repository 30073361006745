import { createSelector } from 'reselect';
import { getEntities, getResults } from 'reducers';
import Immutable from 'immutable';
import { denormalize } from 'normalizr';
import { post } from 'schemas';

export const getPosts = createSelector(
  state => getResults(state).get('posts', Immutable.List()),
  state => getEntities(state),
  (result, entities) =>
    denormalize(
      result,
      post.schemasArray,
      entities,
    ),
);

export const getPostsMeta = state => getResults(state).get('postsMeta', Immutable.List());
