import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';


const BaseField = ({
  input: { value, onChange },
  id,
  fullWidth,
  disabled,
  styleWrapper,
  style,
  meta: {
    touched, error,
  },
  label,
  dropdownItems,
  firstOptionLabel,
  selectedItem
}) => {
  useEffect(() => {
    if (dropdownItems && dropdownItems.size) {
      onChange(dropdownItems.first().id)
    }
  }, [])

  return <div
    style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      ...styleWrapper,
    }}
  >
    {
      label && (
        <InputLabel htmlFor={id}>
          {label}
        </InputLabel>
      )
    }
    <NativeSelect
      value={selectedItem ? selectedItem : value}
      defaultValue={selectedItem}
      onChange={onChange}
      fullWidth={fullWidth}
      disabled={disabled}
      style={style}
    >
      {firstOptionLabel && <option style={{display: 'none'}}>{firstOptionLabel}</option>}
      {
        dropdownItems.map((item, index) => (
          <option value={item.id} key={item.id}>
            {item.title}
          </option>
        ))
      }
    </NativeSelect>
    {touched && error && (
      <span className='field-error'>
        {error}
      </span>
    )}
  </div>
};

BaseField.propTypes = {
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.any,
  styleWrapper: PropTypes.any,
  input: PropTypes.shape({
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.any,
  label: PropTypes.string,
  dropdownItems: PropTypes.array.isRequired,
  firstOptionLabel: PropTypes.string,
  selectedItem: PropTypes.object,
};

BaseField.defaultProps = {
  fullWidth: false,
  disabled: false,
  styleWrapper: {},
  label: '',
};

export default BaseField;
