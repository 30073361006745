import endpoints from 'api/endpoints';

export default ({ version, id }) => ({
  url: endpoints.deleteTermsUrl(version),
  queryKey: `delete${endpoints.deleteTermsUrl()}`,
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: 'application/json',
    },
    method: 'DELETE',
  },
  update: {
    term: (prevEntities) => prevEntities.filter((item) => item.get('id') !== id),
  },
  updateResult: {
    terms: (prevResult) => {
      return prevResult.filter((item) => item.get('id') !== id);
    },
  },
});
