import React from 'react';
import PropTypes from 'prop-types';
import { compose, withState, lifecycle, withHandlers } from 'recompose';
import { forms, apiHOCs } from 'components';
import moment from 'moment';
import _ from 'lodash';

import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

const NewsScreen = ({
  modal,
  setModal,
  initNews,
  setInitNews,
  selected,
  setSelected,
  news,
  newsMeta,
  showMore,
  deleteNews,
  postCreateNews,
  putNews,
  getSingleNews,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Paper
        elevation={1}
        style={{
          width: 800,
          padding: 10,
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        <Typography
          variant="headline"
          component="h3"
          style={{
            textAlign: 'center',
          }}
        >
          News
        </Typography>
      </Paper>
      <Paper
        elevation={1}
        style={{
          width: 820,
          padding: 10,
        }}
      >
        <List>
          {news.map((item) => (
            <ListItem
              key={item.get('id')}
              button
              onClick={() => {
                getSingleNews(item.get('commonId'), item.get('locale')).then((res) => {
                  const resBody = _.get(res, 'body.data.basic');
                  if (resBody) {
                    setInitNews({
                      locale: item.get('locale'),
                      commonId: item.get('commonId'),
                      title: resBody.title,
                      description: item.get('description'),
                      icon: resBody.icon,
                      body: resBody.body,
                      isInternal: resBody.isInternal,
                    });
                  }
                  setSelected(item.get('id'));
                  setModal(true);
                });
              }}
            >
              <ListItemText
                primary={`Title: ${item.get('title')} Id: ${item.get('commonId')}`}
                secondary={`${moment(item.get('createdAt')).format(
                  'MMMM Do YYYY, HH:mm:ss',
                )}, locale: ${item.get('locale')}`}
              />
              <Button
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  deleteNews(item.get('id'));
                }}
                color="secondary"
              >
                DELETE
              </Button>
            </ListItem>
          ))}
        </List>
        {newsMeta && newsMeta.get('total') > 1 && (
          <Button
            variant="contained"
            onClick={showMore}
            color="primary"
            style={{ marginBottom: 30 }}
            fullWidth
          >
            SHOW MORE
          </Button>
        )}
        <Button variant="contained" onClick={() => setModal(true)} color="primary" fullWidth>
          ADD NEW
        </Button>
      </Paper>
      <Modal
        open={modal}
        onClose={() => {
          setModal(false);
          setInitNews({ newsId: '', images: [] });
          setSelected('');
        }}
      >
        <Paper
          elevation={1}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: 1000,
            height: 700,
            overflow: 'auto',
            padding: 20,
          }}
        >
          {modal && (
            <forms.NewsForm
              onSubmit={(body) =>
                selected === ''
                  ? postCreateNews({
                      requestBody: {
                        locale: body.get('locale'),
                        commonId: body.get('commonId'),
                        title: body.get('title'),
                        description: body.get('description'),
                        icon: body.get('icon'),
                        body: body.get('body'),
                        isInternal: body.get('isInternal'),
                      },
                    }).then((resp) => {
                      if (!resp.body.error) {
                        setModal(false);
                      } else {
                        alert('postCreateNews error');
                        console.log(JSON.stringify(resp.body.data));
                      }
                    })
                  : putNews({
                      requestBody: {
                        id: selected,
                        title: body.get('title'),
                        description: body.get('description'),
                        icon: body.get('icon'),
                        body: body.get('body'),
                        isInternal: body.get('isInternal'),
                      },
                    }).then((resp) => {
                      if (!resp.body.error) {
                        setModal(false);
                      } else {
                        alert('putNews error');
                        console.log(JSON.stringify(resp.body.data));
                      }
                    })
              }
              type={selected === '' ? 'create' : 'save'}
              initialValues={initNews}
            />
          )}
        </Paper>
      </Modal>
    </div>
  );
};

NewsScreen.propTypes = {
  modal: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  initNews: PropTypes.object.isRequired,
  setInitNews: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  news: PropTypes.object.isRequired,
  newsMeta: PropTypes.object.isRequired,
  showMore: PropTypes.func.isRequired,
  deleteNews: PropTypes.func.isRequired,
  postCreateNews: PropTypes.func.isRequired,
  putNews: PropTypes.func.isRequired,
  getSingleNews: PropTypes.func.isRequired,
};

export default compose(
  apiHOCs.StoriesApiHOC(),
  apiHOCs.NewsApiHOC(),
  withState('modal', 'setModal', false),
  withState('initNews', 'setInitNews', {}),
  withState('selected', 'setSelected', ''),

  withHandlers({
    showMore:
      ({ newsMeta, getNews }) =>
      () => {
        getNews({
          page: 1,
          count: newsMeta.get('limit') + 20,
        }).then((resp) => {
          if (resp.body.error) {
            alert('getNews error');
            console.log(JSON.stringify(resp.body.data));
          }
        });
      },
  }),

  lifecycle({
    componentDidMount() {
      this.props.getNews({
        page: 1,
        count: 20,
      });
    },
  }),
)(NewsScreen);
