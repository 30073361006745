import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Field, reduxForm, formValueSelector } from 'redux-form/immutable';
import { ui } from 'components';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import { minLength, required } from 'validators';
import { LOCALES } from '../../../const/locale';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

const minLength1 = minLength(1);

const TermsForm = ({
  handleSubmit,
  editorValue,
  type,
  isCreateTemrForCurrentLang,
  version,
  lang,
  deleteTerms,
  setModal,
  selectedTerm,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <form className="auth-form" autoComplete="off">
      <Field
        component={ui.Fields.BaseField}
        name="version"
        type="number"
        maxLength={100}
        validate={minLength1}
        props={{
          id: 'version',
          label: 'version',
          fullWidth: true,
        }}
        disabled={type === 'save'}
      />
      <Field
        component={ui.Fields.SelectField}
        dropdownItems={LOCALES}
        styleWrapper={{ marginTop: '10px', marginBottom: '10px' }}
        style={{ marginBottom: '10px' }}
        name="lang"
        maxLength={150}
        validate={required}
        props={{
          id: 'lang',
          label: 'lang',
          fullWidth: true,
        }}
        multiple={false}
      />
      <Field
        component={ui.Fields.MarkdownField}
        name="body"
        minRows={4}
        maxRows={10}
        label="Terms body:"
        styleWrapper={{ marginTop: 20 }}
        validate={required}
      />
      <h1 style={{ margin: 20 }}>Preview:</h1>
      <ReactMarkdown source={editorValue} className="markdown" />
      <List>
        <Button
          variant="outlined"
          onClick={handleSubmit}
          style={{
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          {type === 'create' ? 'CREATE' : isCreateTemrForCurrentLang ? 'SAVE' : 'CREATE'}
        </Button>
        {type === 'save' && (
          <Button
            variant="contained"
            style={{ marginLeft: '20px' }}
            onClick={(e) => {
              e.stopPropagation();
              handleClickOpen();
            }}
            color="secondary"
          >
            DELETE
          </Button>
        )}
      </List>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title-termsForm">
          {'Are you sure you want to delete terms for this lang ?'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleClose();
              deleteTerms({
                version: version,
                lang: lang,
                id: selectedTerm.get('id'),
              });
              setModal(false);
            }}
            autoFocus
          >
            Confirm deletion
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};

TermsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  editorValue: PropTypes.string.isRequired,
  isCreateTemrForCurrentLang: PropTypes.bool.isRequired,
  deleteTerms: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  selectedTerm: PropTypes.object.isRequired,
};

const selector = formValueSelector('TermsForm');

export default compose(
  connect((state) => ({
    editorValue: selector(state, 'body'),
    lang: selector(state, 'lang'),
    version: selector(state, 'version'),
  })),
  reduxForm({
    form: 'TermsForm',
    enableReinitialize: true,
  }),
)(TermsForm);
