import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import endpoints from 'api/endpoints';
import { requestAsync, querySelectors } from '@digitalwing.co/redux-query-immutable';
import { connect } from 'react-redux';
import { faq } from 'api';

import { getFaqTopics, getArticle } from './selectors';

const FaqApiHOC = () => WrappedComponent => compose(
  connect(
    state => ({
      topics: getFaqTopics(state),
      article: getArticle(state),

      articleIsFetching: querySelectors.isPending(
        state.get('queries'),
        { queryKey: endpoints.getFaqArticleUrl() },
      ) || false,
    }),
    dispatch => ({
      ...bindActionCreators({
        getFaqTopics: () => requestAsync(
          faq.getFaqTopics(),
        ),
        postTopic: args => requestAsync(
          faq.postTopic(args),
        ),
        putTopic: args => requestAsync(
          faq.putTopic(args),
        ),
        deleteTopic: args => requestAsync(
          faq.deleteTopic(args),
        ),
        getFaqArticle: args => requestAsync(
          faq.getFaqArticle(args),
        ),
        postArticle: args => requestAsync(
          faq.postArticle(args),
        ),
        putArticle: args => requestAsync(
          faq.putArticle(args),
        ),
        deleteArticle: args => requestAsync(
          faq.deleteArticle(args),
        ),
      }, dispatch),
    }),
  ),
)(WrappedComponent);

export default FaqApiHOC;
