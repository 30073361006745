import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { compose, withState, lifecycle, withProps } from 'recompose';
import { ui, forms, apiHOCs } from 'components';

import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

const FAQArticleScreen = ({
  topic,
  postArticle,
  putArticle,
  deleteArticle,
  modal,
  setModal,
  selected,
  setSelected,
  dropdownItems,
  topicId,
  article,
  getFaqArticle,
  articleIsFetching,
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Paper
      elevation={1}
      style={{
        width: 800,
        padding: 10,
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      <Typography
        variant="headline"
        component="h3"
        style={{
          textAlign: 'center',
        }}
      >
        {`FAQ Section: ${topic.get('title')}`}
      </Typography>
    </Paper>
    <Paper
      elevation={1}
      style={{
        width: 820,
        padding: 10,
      }}
    >
      <List>
        {
          topic.get('articles', []).map(itemArticle => (
            <ListItem
              key={itemArticle.get('id')}
              button
              onClick={() => {
                setSelected(itemArticle.get('id'));
                getFaqArticle({ id: itemArticle.get('commonId'), locale: topic.get('locale') });
                setModal(true);
              }}
            >
              <ListItemText
                primary={itemArticle.get('title')}
                secondary={itemArticle.get('preview')}
              />
              <Button
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  deleteArticle({ id: itemArticle.get('id') || itemArticle.get('id'), topicId: topic.get('id') });
                }}
                color="secondary"
              >
                DELETE
              </Button>
            </ListItem>
          ))
        }
      </List>
      <Button
        variant="contained"
        onClick={() => setModal(true)}
        color="primary"
        fullWidth
      >
        ADD NEW
      </Button>
    </Paper>
    <Modal
      open={modal}
      onClose={() => {
        setModal(false);
        setSelected('');
      }}
    >
      <Paper
        elevation={1}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: 700,
          height: 500,
          overflow: 'auto',
          padding: 20,
        }}
      >
        {
          modal && !articleIsFetching ? (
            <forms.FaqArticleForm
              onSubmit={body =>
                selected === ''
                  ? postArticle({ body: body.toJS(), topicId: topic.get('id') }).then((resp) => {
                    if (!resp.body.error) {
                      setModal(false);
                    } else {
                      alert('postArticle error');
                      console.log(JSON.stringify(resp.body.data));
                    }
                  })
                  : putArticle({ body: body.toJS(), id: article.get('id'), topicId: topic.get('id') }).then((resp) => {
                    if (!resp.body.error) {
                      setModal(false);
                    } else {
                      alert('putArticle error');
                      console.log(JSON.stringify(resp.body.data));
                    }
                  })}
              type={selected === '' ? 'create' : 'save'}
              dropdownItems={dropdownItems}
              initialValues={{
                topicId,
                ...(selected !== '' ? { body: article.get('body'), title: article.get('title'), commonId: article.get('commonId') } : {}),
              }}
            />
          ) : <ui.LoadingSpinner style={{ alignItems: 'center', justifyContent: 'center', height: '100%' }} />
        }
      </Paper>
    </Modal>
  </div>
);

FAQArticleScreen.propTypes = {
  topic: PropTypes.object.isRequired,
  postArticle: PropTypes.func.isRequired,
  putArticle: PropTypes.func.isRequired,
  deleteArticle: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  dropdownItems: PropTypes.array.isRequired,
  topicId: PropTypes.string.isRequired,
  article: PropTypes.object.isRequired,
  getFaqArticle: PropTypes.func.isRequired,
  articleIsFetching: PropTypes.bool.isRequired,
};

FAQArticleScreen.defaultProps = {
};

export default compose(
  withState('modal', 'setModal', false),
  withState('selected', 'setSelected', ''),

  apiHOCs.FaqApiHOC(),
  withProps(({ topics, match }) => {
    let topic = Immutable.Map();
    if (topics.size) {
      const foundTopic = topics.find(item => item.get('id') === match.params.id);
      if (foundTopic) {
        topic = foundTopic;
      }
    }
    return ({
      dropdownItems: topics.size ? topics.map(item => ({
        id: item.get('id'),
        title: item.get('title'),
      })) : [],
      topic,
      topicId: match.params.id,
    });
  }),

  lifecycle({
    componentDidMount() {
      if (!this.props.topics.size) {
        this.props.getFaqTopics();
      }
    },
  }),
)(FAQArticleScreen);
