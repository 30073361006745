import endpoints from 'api/endpoints';

export default (id) => ({
  url: endpoints.getSingleNewsUrl(id),
  queryKey: endpoints.getSingleNewsUrl(),
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: 'application/json',
    },
    method: 'DELETE',
  },
  update: {
    newsItem: (prevResult) => prevResult.filter((item) => item.get('_id') !== id),
  },
  updateResult: {
    news: (prevResult) => prevResult.filter((item) => item !== id),
  },
});
