import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import endpoints from 'api/endpoints';
import { requestAsync, querySelectors } from '@digitalwing.co/redux-query-immutable';
import { connect } from 'react-redux';
import { news } from 'api';

import { getNews, getNewsMeta } from './selectors';

const NewsApiHOC = () => (WrappedComponent) =>
  compose(
    connect(
      (state) => ({
        news: getNews(state),
        newsMeta: getNewsMeta(state),

        newsIsFetching:
          querySelectors.isPending(state.get('queries'), { queryKey: endpoints.getNewsUrl() }) ||
          false,
      }),
      (dispatch) => ({
        ...bindActionCreators(
          {
            postCreateNews: ({ requestBody }) => requestAsync(news.postCreateNews({ requestBody })),
            getNews: (requestParams) => requestAsync(news.getNews(requestParams)),
            deleteNews: (id) => requestAsync(news.deleteNews(id)),
            putNews: ({ requestBody }) => requestAsync(news.putNews({ requestBody })),
            getSingleNews: (id, locale) => requestAsync(news.getSingleNews(id, locale)),
          },
          dispatch,
        ),
      }),
    ),
  )(WrappedComponent);

export default NewsApiHOC;
