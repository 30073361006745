import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  withState,
  lifecycle,
  withHandlers,
} from 'recompose';
import { forms, apiHOCs } from 'components';
import moment from 'moment';

import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

const PostsScreen = ({
  modal,
  setModal,
  initPost,
  setInitPost,
  selected,
  setSelected,
  showMore,
  putPost,
  posts,
  deletePost,
  postsMeta,
  postCreatePost,
  match,
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Paper
      elevation={1}
      style={{
        width: 800,
        padding: 10,
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      <Typography
        variant="headline"
        component="h3"
        style={{
          textAlign: 'center',
        }}
      >
        Posts
      </Typography>
    </Paper>
    <Paper
      elevation={1}
      style={{
        width: 820,
        padding: 10,
      }}
    >
      <List>
        {
          posts.map(post => (
            <ListItem
              key={post.get('id')}
              button
              onClick={(e) => {
                e.stopPropagation();
                setInitPost({
                  body: post.get('body'),
                  images: post.get('attachments'),
                });
                setSelected(post.get('id'));
                setModal(true);
              }}
            >
              <ListItemText
                primary={`Author: ${post.getIn(['owner', 'username'])} Preview: ${post.get('body').slice(0, 20)}`}
                secondary={moment(post.get('createdAt')).format('MMMM Do YYYY, HH:mm:ss')}
              />
              <Button
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  deletePost(post.get('id'));
                }}
                color="secondary"
              >
                DELETE
              </Button>
            </ListItem>
          ))
        }
      </List>
      {
        postsMeta && postsMeta.get('total') > 1 && (
          <Button
            variant="contained"
            onClick={showMore}
            color="primary"
            style={{ marginBottom: 30 }}
            fullWidth
          >
            SHOW MORE
          </Button>
        )
      }
      <Button
        variant="contained"
        onClick={() => setModal(true)}
        color="primary"
        fullWidth
      >
        ADD NEW
      </Button>
    </Paper>
    <Modal
      open={modal}
      onClose={() => {
        setModal(false);
        setInitPost({});
        setSelected('');
      }}
    >
      <Paper
        elevation={1}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: 1000,
          height: 700,
          overflow: 'auto',
          padding: 20,
        }}
      >
        {
          modal && <forms.PostsForm
            onSubmit={body =>
              selected === ''
                ? postCreatePost({
                  requestBody: {
                    topicId: match.params.id,
                    body: body.get('body'),
                    attachments: body.get('images'),
                    isNotify: false,
                  },
                }).then((resp) => {
                  if (!resp.body.error) {
                    setModal(false);
                  } else {
                    alert('postCreatePost error');
                    console.log(JSON.stringify(resp.body.data));
                  }
                })
                : putPost({
                  requestBody: {
                    id: selected,
                    body: body.get('body'),
                    attachments: body.get('images'),
                  },
                }).then((resp) => {
                  if (!resp.body.error) {
                    setModal(false);
                  } else {
                    alert('putPost error');
                    console.log(JSON.stringify(resp.body.data));
                  }
                })
            }
            type={selected === '' ? 'create' : 'save'}
            initialValues={initPost}
          />
        }
      </Paper>
    </Modal>
  </div>
);

PostsScreen.propTypes = {
  modal: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  initPost: PropTypes.object.isRequired,
  setInitPost: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  showMore: PropTypes.func.isRequired,
  putPost: PropTypes.func.isRequired,
  posts: PropTypes.object.isRequired,
  deletePost: PropTypes.func.isRequired,
  postsMeta: PropTypes.object.isRequired,
  postCreatePost: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

export default compose(
  apiHOCs.PostsApiHOC(),
  withState('modal', 'setModal', false),
  withState('initPost', 'setInitPost', {}),
  withState('selected', 'setSelected', ''),

  withHandlers({
    showMore: ({ postsMeta, getPosts, match }) => () => {
      getPosts(
        match.params.id,
        {
          page: 1,
          count: postsMeta.get('limit') + 20,
      }).then((resp) => {
        if (resp.body.error) {
          alert('getPosts error');
          console.log(JSON.stringify(resp.body.data));
        }
      });
    },
  }),

  lifecycle({
    componentDidMount() {
      this.props.getPosts(this.props.match.params.id);
    },
  }),
)(PostsScreen);
