import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const CheckboxField = ({
  input: { value, onChange },
  id,
  label,
  styleWrapper,
  meta: {
    touched, error,
  },
  disabled,
}) => (
  <div style={{ width: '100%', ...styleWrapper }}>
    <FormControlLabel
      disabled={disabled}
      control={
        <Checkbox
          id={id}
          checked={value}
          onChange={onChange}
          value={value}
          color="primary"
        />
      }
      label={label}
    />
    {touched && error && <span className='field-error'>{error}</span>}
  </div>
);

CheckboxField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  styleWrapper: PropTypes.any,
  input: PropTypes.shape({
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.any,
  disabled: PropTypes.bool,
};

CheckboxField.defaultProps = {
  styleWrapper: {},
  meta: {},
  disabled: false,
};

export default CheckboxField;
