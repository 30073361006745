export const required = value => ((value || typeof value === 'number') ? undefined : 'Обязательное поле');

export const greaterThanZero = value => (value > 0 ? undefined : 'Значение должно быть больше нуля');

export const minLength = min => (value) => {
  if (!value) {
    return `Не менее ${min} символа`;
  }
  return value.trim().length < min ? `Не менее ${min} символа` : undefined;
};

export const notEmptyArray = (value) => {
  if (!Array.isArray(value)) {
    return 'required field';
  }

  return value.length === 0 ? 'required field' : undefined;
};