import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Field, reduxForm, formValueSelector } from 'redux-form/immutable';
import { ui } from 'components';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import ReactMarkdown from 'react-markdown';
import { required } from '../../../validators';

const TopicsForm = ({
  handleSubmit,
  type,
  editorValue,
  invalid,
}) => (
  <form className="auth-form" autoComplete="off">
    <Field
      component={ui.Fields.BaseField}
      name="title"
      validate={required}
      props={{
        id: 'title',
        label: 'Title:',
        fullWidth: true,
        maxLength: 150,
      }}
    />
    {
      type === 'create' && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            // textAlign: 'center',
          }}
        >
          <h2 style={{ margin: '20px auto' }}>
            Message:
          </h2>
          <Field
            component={ui.Fields.MarkdownField}
            name="body"
            minRows={4}
            maxRows={10}
            label="News body:"
          />
          <h3 style={{ margin: '20px auto' }}>
            Preview:
          </h3>
          <ReactMarkdown source={editorValue} className="markdown" />
          <h3 style={{ margin: '20px auto' }}>
            Message images:
          </h3>
          <Field
            component={ui.Fields.MultiImageField}
            name="images"
            props={{
              id: 'icon',
              label: 'icon',
            }}
            returnObjectString
          />
        </div>
      )
    }
    <Button
      variant="outlined"
      onClick={handleSubmit}
      disabled={invalid}
      style={{
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      {type === 'create' ? 'CREATE' : 'SAVE'}
    </Button>
  </form>
);

TopicsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  editorValue: PropTypes.string.isRequired,
  invalid: PropTypes.bool.isRequired,
};

const selector = formValueSelector('topicsForm');

export default compose(
  connect(state => ({
    editorValue: selector(state, 'body'),
  })),
  reduxForm({
    form: 'topicsForm',
  }),
)(TopicsForm);
