import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  withState,
  lifecycle,
  withProps,
  getContext,
} from 'recompose';
import { forms, apiHOCs } from 'components';

import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

const BoardsScreen = ({
  modal,
  setModal,
  initBoard,
  setInitBoard,
  selected,
  setSelected,
  dropdownItems,
  boards,
  postCreateBoard,
  deleteBoard,
  putBoard,
  getBoards,
  router,
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Paper
      elevation={1}
      style={{
        width: 800,
        padding: 10,
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      <Typography
        variant="headline"
        component="h3"
        style={{
          textAlign: 'center',
        }}
      >
        Boards
      </Typography>
    </Paper>
    <Paper
      elevation={1}
      style={{
        width: 820,
        padding: 10,
      }}
    >
      <List>
        {
          boards.map(board => (
            <div key={board.get('id') || board.getIn(['basic', 'id'])}>
              <ListItem
                button
              >
                <ListItemText
                  primary={`Title: ${board.get('title') || board.getIn(['basic', 'title'])} Id: ${board.get('id') || board.getIn(['basic', 'id'])}`}
                />
                <Button
                  variant="contained"
                  onClick={(e) => {
                    e.stopPropagation();
                    setInitBoard({
                      parentId: board.get('parentId') || board.getIn(['basic', 'parentId']),
                      title: board.get('title') || board.getIn(['basic', 'title']),
                      description: board.get('description') || board.getIn(['basic', 'description']),
                      withParent: !!board.get('parentId'),
                      rules: board.get('rules'),
                    });
                    setSelected(board.get('id') || board.getIn(['basic', 'id']));
                    setModal(true);
                  }}
                  color="primary"
                  style={{ marginRight: 15 }}
                >
                  EDIT
                </Button>
                <Button
                  variant="contained"
                  onClick={(e) => {
                    e.stopPropagation();
                    setInitBoard({});
                    deleteBoard(board.get('id') || board.getIn(['basic', 'id']))
                      .then(() => getBoards());
                  }}
                  color="secondary"
                >
                  DELETE
                </Button>
              </ListItem>
              {
                board.get('items') && !!board.get('items').size && board.get('items').map(boardItem => (
                  <ListItem
                    key={boardItem.get('id')}
                    button
                    onClick={() => router.history.push(`/forum/topics/${boardItem.get('id')}`)}
                  >
                    <ListItemText
                      primary={`Title: ${boardItem.get('title')} Id: ${boardItem.get('id')}`}
                    />
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        e.stopPropagation();
                        setInitBoard({
                          withParent: true,
                          parentId: boardItem.get('parentId'),
                          title: boardItem.get('title'),
                          description: boardItem.get('description'),
                          rules: boardItem.get('rules'),
                        });
                        setSelected(boardItem.get('id'));
                        setModal(true);
                      }}
                      color="primary"
                      style={{ marginRight: 15 }}
                    >
                      EDIT
                    </Button>
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteBoard(boardItem.get('id'));
                      }}
                      color="secondary"
                    >
                      DELETE
                    </Button>
                  </ListItem>
                ))
              }
            </div>
          ))
        }
      </List>
      <Button
        variant="contained"
        onClick={() => setModal(true)}
        color="primary"
        fullWidth
      >
        ADD NEW
      </Button>
    </Paper>
    <Modal
      open={modal}
      onClose={() => {
        setModal(false);
        setInitBoard({});
        setSelected('');
      }}
    >
      <Paper
        elevation={1}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: 700,
          height: 500,
          overflow: 'auto',
          padding: 20,
        }}
      >
        {
          modal && <forms.BoardsForm
            onSubmit={body =>
              selected === ''
                ? postCreateBoard({
                  requestBody: {
                    parentId: body.get('parentId'),
                    title: body.get('title'),
                    description: body.get('description'),
                    rules: body.get('rules'),
                  },
                }).then((resp) => {
                  if (!resp.body.error) {
                    setModal(false);
                  } else {
                    alert('postCreateBoard error');
                    console.log(JSON.stringify(resp.body.data));
                  }
                })
                : putBoard({
                  requestBody: {
                    id: selected,
                    title: body.get('title'),
                    description: body.get('description'),
                    rules: body.get('rules'),
                  },
                }).then((resp) => {
                  if (!resp.body.error) {
                    getBoards();
                    setModal(false);
                  } else {
                    alert('putBoard error');
                    console.log(JSON.stringify(resp.body.data));
                  }
                })
            }
            type={selected === '' ? 'create' : 'save'}
            initialValues={initBoard}
            dropdownItems={dropdownItems}
          />
        }
      </Paper>
    </Modal>
  </div>
);

BoardsScreen.propTypes = {
  modal: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  initBoard: PropTypes.object.isRequired,
  setInitBoard: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  dropdownItems: PropTypes.array.isRequired,
  boards: PropTypes.object.isRequired,
  postCreateBoard: PropTypes.func.isRequired,
  deleteBoard: PropTypes.func.isRequired,
  putBoard: PropTypes.func.isRequired,
  getBoards: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
};

export default compose(
  apiHOCs.BoardsApiHOC(),
  withState('modal', 'setModal', false),
  withState('initBoard', 'setInitBoard', {}),
  withState('selected', 'setSelected', ''),

  getContext({
    router: PropTypes.shape({
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
      }).isRequired,
    }).isRequired,
  }),

  withProps(({ boards }) => ({
    dropdownItems: boards.size ? boards.map(item => ({
      id: item.get('id') || item.getIn(['basic', 'id']),
      title: item.get('title') || item.getIn(['basic', 'title']),
    })) : [],
  })),

  lifecycle({
    componentDidMount() {
      this.props.getBoards();
    },
  }),
)(BoardsScreen);
