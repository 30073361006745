import React from 'react';
import {
  compose,
  getContext,
  withState,
  // withProps,
} from 'recompose';
import { token } from 'helpers';
import { apiHOCs } from 'components';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';

import './style.scss';

const NavBar = ({
  anchorEl,
  setAnchor,
  user,
  isDrawerOpen,
  setDrawerOpen,
  router,
  postLogOut,
}) =>
  (
    <div
      style={{
        flexGrow: 1,
      }}
    >
      <AppBar position="static" color="default">
        <Toolbar>
          <IconButton
            style={{
              marginLeft: -12,
              marginRight: 20,
            }}
            color="inherit"
            aria-label="Menu"
            onClick={() => setDrawerOpen(!isDrawerOpen)}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="title"
            color="inherit"
            style={{
              flexGrow: 1,
            }}
          >
            Site Admin
          </Typography>
          <div>
            <Button
              aria-owns={anchorEl ? 'menu-appbar' : null}
              aria-haspopup="true"
              onClick={e => setAnchor(e.currentTarget)}
            >
              <AccountCircle style={{ marginRight: 10 }} />
              {user.get('name')}
            </Button>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={() => setAnchor(null)}
            >
              <MenuItem onClick={() => setAnchor(null)}>
                My account
              </MenuItem>
              <MenuItem onClick={postLogOut}>
                Log out
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer open={isDrawerOpen} onClose={() => setDrawerOpen(false)}>
        <div
          tabIndex={0}
          role="button"
          onClick={() => setDrawerOpen(false)}
        >
          <div
            style={{
              width: 350,
            }}
          >
            <List>
              <ListItem button onClick={() => router.history.push('/news')}>
                <ListItemText primary="News" />
              </ListItem>
              <ListItem button onClick={() => router.history.push('/stories')}>
                <ListItemText primary="Stories" />
              </ListItem>
              <ListItem button onClick={() => router.history.push('/faq')}>
                <ListItemText primary="FAQ" />
              </ListItem>
              <ListItem button onClick={() => router.history.push('/forum/boards')}>
                <ListItemText primary="Forum boards" />
              </ListItem>
              <ListItem button onClick={() => router.history.push('/forum/users')}>
                <ListItemText primary="Forum users" />
              </ListItem>
              <ListItem button onClick={() => router.history.push('/translations/tree')}>
                <ListItemText primary="Translations tree" />
              </ListItem>
              <ListItem button onClick={() => router.history.push('/terms')}>
                <ListItemText primary="Terms" />
              </ListItem>
            </List>
          </div>
        </div>
      </Drawer>
    </div>
  );

NavBar.propTypes = {
  anchorEl: PropTypes.element,
  // accessRights: PropTypes.array,
  setAnchor: PropTypes.func,
  user: PropTypes.object,
  isDrawerOpen: PropTypes.bool,
  setDrawerOpen: PropTypes.func,
  router: PropTypes.object,
  postLogOut: PropTypes.func,
};

export default compose(
  apiHOCs.AuthApiHOC(),
  apiHOCs.UsersApiHOC(),
  // withProps(({ user }) => ({
  //   accessRights: user.get('access').toJS(),
  // })),

  withState('anchorEl', 'setAnchor', null),
  withState('isDrawerOpen', 'setDrawerOpen', false),

  getContext({
    router: PropTypes.shape({
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
      }).isRequired,
    }).isRequired,
  }),
)(NavBar);
