import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Field, reduxForm } from 'redux-form/immutable';
import { ui } from 'components';
import Button from '@material-ui/core/Button';
import { minLength, required } from 'validators';
import { LOCALES } from '../../../const/locale';

const minLength6 = minLength(6);
const minLength3 = minLength(3);

const FaqForm = ({
  handleSubmit,
  type,
}) => (
  <form className="auth-form" autoComplete="off">
    <Field
      component={ui.Fields.BaseField}
      name="commonId"
      maxLength={150}
      validate={minLength3}
      props={{
        id: 'commonId',
        label: 'Common ID',
        fullWidth: true,
      }}
    />
    <Field
      component={ui.Fields.BaseField}
      name="title"
      maxLength={150}
      validate={minLength6}
      props={{
        id: 'title',
        label: 'Title',
        fullWidth: true,
      }}
    />
    <Field
      component={ui.Fields.DropdownField}
      dropdownItems={LOCALES}
      styleWrapper={{marginTop: '30px', marginBottom: '10px'}}
      style={{marginBottom: '10px'}}
      name="locale"
      maxLength={150}
      validate={required}
      props={{
        id: 'locale',
        label: 'Locale',
        fullWidth: true,
      }}
      firstOptionLabel={'-'}
      disabled={type === 'save'}
    />
    <Field
      component={ui.Fields.ImageField}
      name="icon"
      label="Icon:"
      validate={required}
      style={{marginBottom: '10px'}}
    />
    <Button
      variant="outlined"
      onClick={handleSubmit}
      style={{
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      {type === 'create' ? 'CREATE' : 'SAVE'}
    </Button>
  </form>
);

FaqForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default compose(
  reduxForm({
    form: 'faqForm',
  }),
)(FaqForm);
