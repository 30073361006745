import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import './style.scss';

const SelectField = ({
  input: { value, onChange },
  dropdownItems,
  id,
  label,
  type,
  fullWidth,
  multiple,
  disabled,
  styleWrapper,
  style,
  maxLength,
  meta: {
   touched, error,
  },
}) => {
  
  return<div style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      ...styleWrapper,
    }}>
      <FormControl>
        {
        label && (
          <InputLabel htmlFor={id}>
            {label}
          </InputLabel>
        )
        }
        <Select
          id={id}
          label={label}
          value={multiple ? [...value] : value}
          type={type}
          onChange={onChange}
          fullWidth={fullWidth}
          multiple={multiple}
          disabled={disabled}
          style={style}
          inputProps={{ maxLength }}
          renderValue={(selected) => multiple ? selected.join(', ') : selected}
        >
          {dropdownItems.map((item) => (
                <MenuItem key={item?.id} value={item?.title}>
                  <Checkbox checked={value.indexOf(item?.title) > -1} />
                  <ListItemText primary={item?.title} />
                </MenuItem>
              ))}
        </Select>
        {touched && error && (
          <span className='field-error'>
            {error}
          </span>
        )}
      </FormControl>
  </div>
};

SelectField.propTypes = {
  id: PropTypes.string,
  dropdownItems: PropTypes.array,
  label: PropTypes.string,
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.any,
  styleWrapper: PropTypes.any,
  input: PropTypes.shape({
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.any,
};

SelectField.defaultProps = {
  type: 'text',
  fullWidth: false,
  multiple: false,
  disabled: false,
  styleWrapper: {},
  dropdownItems: [],
};

export default SelectField;
