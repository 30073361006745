import endpoints from 'api/endpoints';
import { normalize } from 'normalizr';
import { post } from 'schemas';
import Immutable from 'immutable';

export default (id, params = { page: 1, count: 20 }) => ({
  url: endpoints.getSingleTopicUrl(id, params),
  queryKey: endpoints.getSingleTopicUrl(),
  transform: response =>
    normalize(response.data.messages, post.schemasArray).entities,
  transformResult: response => ({
    posts: normalize(response.data.messages, post.schemasArray).result,
    postsMeta: response.meta,
  }),
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: 'application/json',
    },
    method: 'GET',
  },
  update: {
    post: (prevEntities = Immutable.Map(), nextEntities) =>
      prevEntities.mergeDeep(nextEntities),
  },
  updateResult: {
    posts: (_, result) => result,
    postsMeta: (prevResult, result) => result,
  },
});
