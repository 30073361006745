import { createSelector } from 'reselect';
import { getEntities, getResults } from 'reducers';
import Immutable from 'immutable';
import { denormalize } from 'normalizr';
import { story } from 'schemas';

export const getStories = createSelector(
  state => getResults(state).get('stories', Immutable.List()),
  state => getEntities(state),
  (result, entities) =>
    denormalize(
      result,
      story.schemasArray,
      entities,
    ),
);
