import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Field, reduxForm, formValueSelector } from 'redux-form/immutable';
import { ui } from 'components';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import ReactMarkdown from 'react-markdown';

const PostsForm = ({
  handleSubmit,
  type,
  editorValue,
  initialValues,
}) => (
  <form className="auth-form" autoComplete="off">
    <Field
      component={ui.Fields.MarkdownField}
      name="body"
      minRows={4}
      maxRows={10}
      label="News body:"
    />
    <h3 style={{ margin: '20px auto' }}>
      Preview:
    </h3>
    <ReactMarkdown source={editorValue} className="markdown" />
    <h3 style={{ margin: '20px auto' }}>
      Message files:
    </h3>
    <Field
      component={ui.Fields.MultiImageField}
      name="images"
      initImages={initialValues ? initialValues.get('images') : []}
      props={{
        id: 'icon',
        label: 'icon',
      }}
      returnObjectString
    />
    <Button
      variant="outlined"
      onClick={handleSubmit}
      style={{
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      {type === 'create' ? 'CREATE' : 'SAVE'}
    </Button>
  </form>
);

PostsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  editorValue: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
};

PostsForm.defaultProps = {
  initialValues: null,
};

const selector = formValueSelector('postsForm');

export default compose(
  connect(state => ({
    editorValue: selector(state, 'body'),
  })),
  reduxForm({
    form: 'postsForm',
  }),
)(PostsForm);
