import endpoints from 'api/endpoints';

export default ({ id }) => ({
  url: endpoints.getFaqTopicByIdUrl(id),
  queryKey: `delete${endpoints.getFaqTopicByIdUrl()}`,
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: 'application/json',
    },
    method: 'DELETE',
  },
  update: {
    topicFaq: prevEntities => prevEntities.filter(item => item.get('id') !== id),
  },
  updateResult: {
    topicsFaq: prevResult => prevResult.filter(item => item !== id),
  },
});
