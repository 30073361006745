import endpoints from 'api/endpoints';
import { normalize } from 'normalizr';
import { news } from 'schemas';
import Immutable from 'immutable';

export default (id, locale) => ({
  url: endpoints.getSingleNewsUrl(id, locale),
  queryKey: `get${endpoints.getSingleNewsUrl()}`,
  transform: (response) => normalize(response.data.basic, news.schema).entities,
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: 'application/json',
    },
    method: 'GET',
  },
  update: {
    newsItem: (prevStory = Immutable.Map(), nextStory) => prevStory.mergeDeep(nextStory),
  },
});
