import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import endpoints from 'api/endpoints';
import { requestAsync, querySelectors } from '@digitalwing.co/redux-query-immutable';
import { connect } from 'react-redux';
import { story } from 'api';

import { getStories } from './selectors';

const StoriesApiHOC = () => WrappedComponent => compose(
  connect(
    state => ({
      stories: getStories(state),

      storiesIsFetching: querySelectors.isPending(
        state.get('queries'),
        { queryKey: endpoints.getStoryUrl() },
      ) || false,
      deleteStoryIsFetching: querySelectors.isPending(
        state.get('queries'),
        { queryKey: endpoints.getDeleteStoryUrl() },
      ) || false,
      createStoryIsFetching: querySelectors.isPending(
        state.get('queries'),
        { queryKey: `post${endpoints.getStoryUrl()}` },
      ) || false,
    }),
    dispatch => ({
      ...bindActionCreators({
        postCreateStory: ({ requestBody }) => requestAsync(
          story.postCreateStory({ requestBody }),
        ),
        putStory: ({ requestBody }) => requestAsync(
          story.putStory({ requestBody }),
        ),
        getStories: () => requestAsync(
          story.getStories(),
        ),
        deleteStory: id => requestAsync(
          story.deleteStory(id),
        ),
      }, dispatch),
    }),
  ),
)(WrappedComponent);

export default StoriesApiHOC;
