import getBoards from './getBoards';
import postCreateBoard from './postCreateBoard';
import deleteBoard from './deleteBoard';
import putBoard from './putBoard';

export default {
  getBoards,
  postCreateBoard,
  deleteBoard,
  putBoard,
};
