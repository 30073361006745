import endpoints from 'api/endpoints';
import { normalize } from 'normalizr';
import { topic } from 'schemas';
import Immutable from 'immutable';

export default (id, params = { page: 1, count: 20 }) => ({
  url: endpoints.getSingleBoardUrl(id, params),
  queryKey: endpoints.getSingleBoardUrl(),
  transform: response =>
    normalize(response.data.items, topic.schemasArray).entities,
  transformResult: response => ({
    topics: normalize(response.data.items, topic.schemasArray).result,
    topicsMeta: response.meta,
  }),
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: 'application/json',
    },
    method: 'GET',
  },
  update: {
    topic: (prevEntities = Immutable.Map(), nextEntities) =>
      prevEntities.mergeDeep(nextEntities),
  },
  updateResult: {
    topics: (_, result) => result,
    topicsMeta: (prevResult, result) => result,
  },
});
