import React from 'react';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    margin: '30px 0',
  },
});

function JsonTree({ handleUpdate, data }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
       <ReactJson
        src={data}
        onEdit={handleUpdate}
        // onAdd={handleUpdate}
        // onDelete={handleUpdate}
        enableClipboard={false}
        collapsed={true}
        name={null}
       />
    </div>
  );
}

JsonTree.propTypes = {
  handleUpdate: PropTypes.func.isRequired,
  data: PropTypes.array,
};

export default JsonTree;
