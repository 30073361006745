import React from 'react';
import { apiHOCs, ui } from 'components';
import {
  branch,
  compose,
  renderComponent,
  withProps,
} from 'recompose';
import { Redirect, Route, Switch } from 'react-router';
import * as screens from 'screens';

import './style.scss';

const HomeScreen = () => (
  <div className="home-layout">
    <ui.NavBar />
    <Switch>
      <Route path="/translations/tree" component={screens.TranslationsTreeScreen} />
      <Route path="/forum/users" component={screens.UsersScreen} />
      <Route path="/forum/posts/:id" component={screens.PostsScreen} />
      <Route path="/forum/topics/:id" component={screens.TopicsScreen} />
      <Route path="/forum/boards" component={screens.BoardsScreen} />
      <Route path="/news" component={screens.NewsScreen} />
      <Route path="/terms" component={screens.TermsScreen} />
      <Route path="/stories" component={screens.StoriesScreen} />
      <Route path="/faq/:id" component={screens.FAQArticleScreen} />
      <Route path="/" component={screens.FAQSectionScreen} />
    </Switch>
  </div>
);

const redirectComponent = withProps({ to: '/signin' })(Redirect);

export default compose(
  apiHOCs.AuthApiHOC(),

  branch(
    ({ user }) => !(
      user
      && user.size
      && (user.get('role') === 'moderator' || user.get('role') === 'admin')
    ),
    renderComponent(redirectComponent),
  ),
)(HomeScreen);
