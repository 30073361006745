import endpoints from 'api/endpoints';
import { normalize } from 'normalizr';
import { story } from 'schemas';
import Immutable from 'immutable';

export default () => ({
  url: endpoints.getStoryUrl(),
  queryKey: endpoints.getStoryUrl(),
  transform: response =>
    normalize(response.data, story.schemasArray).entities,
  transformResult: response => ({
    stories: normalize(response.data, story.schemasArray).result,
  }),
  meta: {
    authToken: true,
  },
  options: {
    headers: {
      Accept: 'application/json',
    },
    method: 'GET',
  },
  update: {
    story: (prevStory = Immutable.Map(), nextStory) =>
      prevStory.mergeDeep(nextStory),
  },
  updateResult: {
    stories: (_, result) => result,
  },
});
