import { normalize } from 'normalizr';
import endpoints from 'api/endpoints';
import { topicFaq } from 'schemas';
import Immutable from 'immutable';

export default ({ body }) => ({
  url: endpoints.getFaqTopicsUrl(),
  queryKey: `post${endpoints.getFaqTopicsUrl()}`,
  transform: response => normalize(response.data, topicFaq.schema).entities,
  transformResult: response => ({
    topicsFaq: normalize(response.data, topicFaq.schema).result,
  }),
  body,
  force: true,
  meta: {
    authToken: true,
  },
  options: {
    headers: {
      Accept: 'application/json',
    },
    method: 'POST',
  },
  update: {
    topicFaq: (prevEntities = Immutable.Map(), nextEntities) =>
      prevEntities.mergeDeep(nextEntities),
  },
  updateResult: {
    topicsFaq: (nextEntities = Immutable.List(), nextResult) => nextEntities.push(nextResult),
  },
});
