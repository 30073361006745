import React from 'react';
import PropTypes from 'prop-types';
import { compose, withState, lifecycle } from 'recompose';
import { forms, apiHOCs } from 'components';

import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

const FAQSectionScreen = ({
  topics,
  postTopic,
  putTopic,
  deleteTopic,
  modal,
  setModal,
  initFaq,
  setInitFaq,
  selected,
  setSelected,
  history,
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Paper
      elevation={1}
      style={{
        width: 800,
        padding: 10,
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      <Typography
        variant="headline"
        component="h3"
        style={{
          textAlign: 'center',
        }}
      >
        FAQ Section
      </Typography>
    </Paper>
    <Paper
      elevation={1}
      style={{
        width: 820,
        padding: 10,
      }}
    >
      <List>
        {
          topics.map(topic => (
            <ListItem
              key={topic.get('id') + topic.get('commonId')}
              button
              onClick={() => {
                history.push(`/faq/${topic.get('id')}`);
              }}
            >
              <ListItemText
                primary={topic.get('title')}
                secondary={`articles: ${topic.get('articles', []).size}`}
              />
              <Button
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  setInitFaq({ title: topic.get('title'), icon: topic.get('icon'), commonId: topic.get('commonId'), locale: topic.get('locale') });
                  setSelected(topic.get('id'));
                  setModal(true);
                }}
                color="primary"
                style={{
                  marginRight: 20,
                }}
              >
                EDIT
              </Button>
              <Button
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  deleteTopic({ id: topic.get('id') });
                }}
                color="secondary"
              >
                DELETE
              </Button>
            </ListItem>
          ))
        }
      </List>
      <Button
        variant="contained"
        onClick={() => setModal(true)}
        color="primary"
        fullWidth
      >
        ADD NEW
      </Button>
    </Paper>
    <Modal
      open={modal}
      onClose={() => {
        setModal(false);
        setInitFaq({ title: '', icon: '', commonId: '', locale: '' });
        setSelected('');
      }}
    >
      <Paper
        elevation={1}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: 700,
          height: '100%',
          overflow: 'auto',
          padding: 20,
          maxHeight: '70vh'
        }}
      >
        {
          modal && <forms.FaqForm
            onSubmit={body =>
              selected === ''
                ? postTopic({ body: body.toJS() }).then((resp) => {
                  if (!resp.body.error) {
                    setModal(false);
                  } else {
                    alert('postTopic error');
                    console.log(JSON.stringify(resp.body.data));
                  }
                })
                : putTopic({ body: body.toJS(), id: selected }).then((resp) => {
                  if (!resp.body.error) {
                    setModal(false);
                  } else {
                    alert('putTopic error');
                    console.log(JSON.stringify(resp.body.data));
                  }
                })}
            type={selected === '' ? 'create' : 'save'}
            initialValues={selected ? initFaq : {}}
          />
        }
      </Paper>
    </Modal>
  </div>
);

FAQSectionScreen.propTypes = {
  topics: PropTypes.object.isRequired,
  postTopic: PropTypes.func.isRequired,
  putTopic: PropTypes.func.isRequired,
  deleteTopic: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  initFaq: PropTypes.object.isRequired,
  setInitFaq: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

FAQSectionScreen.defaultProps = {
};

export default compose(
  withState('modal', 'setModal', false),
  withState('initFaq', 'setInitFaq', { title: '', icon: '' }),
  withState('selected', 'setSelected', ''),
  apiHOCs.FaqApiHOC(),
  lifecycle({
    componentDidMount() {
      this.props.getFaqTopics();
    },
  }),
)(FAQSectionScreen);
