import { normalize } from 'normalizr';
import endpoints from 'api/endpoints';
import Immutable from 'immutable';
import { topicFaq } from 'schemas';

export default () => ({
  url: endpoints.getFaqTopicsUrl(),
  transform: response =>
    normalize(response.data, topicFaq.schemasArray).entities,
  transformResult: response => ({
    topicsFaq: normalize(response.data, topicFaq.schemasArray).result,
  }),
  meta: {
    authToken: true,
  },
  force: true,
  queryKey: endpoints.getFaqTopicsUrl(),
  options: {
    headers: {
      Accept: 'application/json',
    },
  },
  update: {
    topicFaq: (prevResult = Immutable.Map(), result) => prevResult.mergeDeep(result),
  },
  updateResult: {
    topicsFaq: (prevResult, result) => result,
  },
});
