import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  withState,
  lifecycle,
  withProps,
} from 'recompose';
import { forms, apiHOCs } from 'components';
import moment from 'moment';

import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

const StoriesScreen = ({
  modal,
  postCreateStory,
  setModal,
  stories,
  initStory,
  setInitStory,
  selected,
  setSelected,
  deleteStory,
  dropdownItems,
  putStory,
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Paper
      elevation={1}
      style={{
        width: 800,
        padding: 10,
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      <Typography
        variant="headline"
        component="h3"
        style={{
          textAlign: 'center',
        }}
      >
        Stories
      </Typography>
    </Paper>
    <Paper
      elevation={1}
      style={{
        width: 820,
        padding: 10,
      }}
    >
      <List>
        {
          stories.map(story => (
            <ListItem
              key={story.get('id')}
              button
              onClick={() => {
                setInitStory({ newsId: story.get('newsId'), images: story.get('images').map(image => image.get('link')) });
                setSelected(story.get('id'));
                setModal(true);
              }}
            >
              <ListItemText
                primary={`News title: ${story.get('newsTitle')} Story id: ${story.get('id')}`}
                secondary={moment(story.get('publishedAt')).format('MMMM Do YYYY, HH:mm:ss')}
              />
              <Button
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  deleteStory(story.get('id'));
                }}
                color="secondary"
              >
                DELETE
              </Button>
            </ListItem>
          ))
        }
      </List>
      <Button
        variant="contained"
        onClick={() => setModal(true)}
        color="primary"
        fullWidth
      >
        ADD NEW
      </Button>
    </Paper>
    <Modal
      open={modal}
      onClose={() => {
        setModal(false);
        setInitStory({ newsId: '', images: [] });
        setSelected('');
      }}
    >
      <Paper
        elevation={1}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: 700,
          height: 500,
          overflow: 'auto',
          padding: 20,
        }}
      >
        {
          modal && <forms.AddStoryForm
            onSubmit={body =>
              selected === ''
                ? postCreateStory({
                  requestBody: {
                    newsId: body.get('newsId'),
                    images: body.get('images'),
                  },
                }).then((resp) => {
                  if (!resp.body.error) {
                    setModal(false);
                  } else {
                    alert('postCreateStory error');
                    console.log(JSON.stringify(resp.body.data));
                  }
                })
                : putStory({
                  requestBody: {
                    id: selected,
                    newsId: body.get('newsId'),
                    images: body.get('images'),
                  },
                }).then((resp) => {
                  if (!resp.body.error) {
                    setModal(false);
                  } else {
                    alert('putStory error');
                    console.log(JSON.stringify(resp.body.data));
                  }
                })
            }
            type={selected === '' ? 'create' : 'save'}
            initialValues={initStory}
            dropdownItems={dropdownItems}
          />
        }
      </Paper>
    </Modal>
  </div>
);

StoriesScreen.propTypes = {
  modal: PropTypes.bool.isRequired,
  postCreateStory: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  stories: PropTypes.object.isRequired,
  initStory: PropTypes.object.isRequired,
  setInitStory: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  deleteStory: PropTypes.func.isRequired,
  dropdownItems: PropTypes.array.isRequired,
  putStory: PropTypes.func.isRequired,
};

export default compose(
  apiHOCs.StoriesApiHOC(),
  apiHOCs.NewsApiHOC(),
  withState('modal', 'setModal', false),
  withState('initStory', 'setInitStory', { newsId: '', images: [] }),
  withState('selected', 'setSelected', ''),

  withProps(({ news }) => ({
    dropdownItems: news.size ? news.map(item => ({
      id: item.get('id'),
      title: item.get('title'),
    })) : [],
  })),

  lifecycle({
    componentDidMount() {
      this.props.getNews({
        page: 1, count: 20, offset: '', isInternal: false,
      });
      this.props.getStories();
    },
  }),
)(StoriesScreen);
