import endpoints from 'api/endpoints';

export default id => ({
  url: endpoints.getDeleteStoryUrl(id),
  queryKey: endpoints.getDeleteStoryUrl(),
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: 'application/json',
    },
    method: 'DELETE',
  },
  update: {
    story: prevResult => prevResult.filter(item => item.get('_id') !== id),
  },
  updateResult: {
    stories: prevResult => prevResult.filter(item => item !== id),
  },
});
