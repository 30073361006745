import endpoints from 'api/endpoints';
import { normalize } from 'normalizr';
import { news } from 'schemas';
import Immutable from 'immutable';

export default ({ requestBody }) => ({
  url: endpoints.getNewsUrl(),
  queryKey: `post${endpoints.getNewsUrl()}`,
  transform: response => normalize(response.data, news.schema).entities,
  transformResult: response => ({
    news: normalize(response.data, news.schema).result,
  }),
  body: requestBody,
  force: true,
  meta: {
    authToken: true,
  },
  options: {
    headers: {
      Accept: 'application/json',
    },
    method: 'POST',
  },
  update: {
    newsItem: (prevStory = Immutable.Map(), nextStory) =>
      prevStory.mergeDeep(nextStory),
  },
  updateResult: {
    news: (prevResult = Immutable.List(), result) => prevResult.push(result),
  },
});
