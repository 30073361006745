import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import endpoints from 'api/endpoints';
import { requestAsync, querySelectors } from '@digitalwing.co/redux-query-immutable';
import { connect } from 'react-redux';
import { user } from 'api';

import { getUsers, getUsersMeta } from './selectors';

const UsersApiHOC = () => WrappedComponent => compose(
  connect(
    state => ({
      users: getUsers(state),
      usersMeta: getUsersMeta(state),

      usersIsFetching: querySelectors.isPending(
        state.get('queries'),
        { queryKey: endpoints.getUsersUrl() },
      ) || false,
    }),
    dispatch => ({
      ...bindActionCreators({
        getUsers: requestParams => requestAsync(
          user.getUsers(requestParams),
        ),
        putBlockUser: ({ requestBody }) => requestAsync(
          user.putBlockUser({ requestBody }),
        ),
        postLogOut: () => requestAsync(
          user.postLogOut(),
        ),
      }, dispatch),
    }),
  ),
)(WrappedComponent);

export default UsersApiHOC;
