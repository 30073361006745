import endpoints from 'api/endpoints';
import { normalize } from 'normalizr';
import { board } from 'schemas';
import Immutable from 'immutable';

export default ({ requestBody }) => ({
  url: endpoints.getSingleBoardUrl(requestBody.id),
  queryKey: `put${endpoints.getSingleBoardUrl()}`,
  transform: response => normalize(response.data, board.schema).entities,
  body: requestBody,
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: 'application/json',
    },
    method: 'PUT',
  },
  update: {
    board: (prevEntities = Immutable.Map()) => prevEntities,
  },
});
