import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import endpoints from 'api/endpoints';
import { requestAsync, querySelectors } from '@digitalwing.co/redux-query-immutable';
import { connect } from 'react-redux';
import { topics } from 'api';

import { getTopics, getTopicsMeta } from './selectors';

const TopicsApiHOC = () => WrappedComponent => compose(
  connect(
    state => ({
      topics: getTopics(state),
      topicsMeta: getTopicsMeta(state),

      topicsIsFetching: querySelectors.isPending(
        state.get('queries'),
        { queryKey: endpoints.getSingleBoardUrl() },
      ) || false,
    }),
    dispatch => ({
      ...bindActionCreators({
        getTopics: (id, params) => requestAsync(
          topics.getTopics(id, params),
        ),
        deleteTopic: id => requestAsync(
          topics.deleteTopic(id),
        ),
        postCreateTopic: ({ requestBody }) => requestAsync(
          topics.postCreateTopic({ requestBody }),
        ),
        putTopic: ({ requestBody }) => requestAsync(
          topics.putTopic({ requestBody }),
        ),
      }, dispatch),
    }),
  ),
)(WrappedComponent);

export default TopicsApiHOC;
