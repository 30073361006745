import getNews from './getNews';
import deleteNews from './deleteNews';
import postCreateNews from './postCreateNews';
import putNews from './putNews';
import getSingleNews from './getSingleNews';

export default {
  getNews,
  deleteNews,
  postCreateNews,
  putNews,
  getSingleNews,
};
