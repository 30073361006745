import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Field, reduxForm, formValueSelector } from 'redux-form/immutable';
import { ui } from 'components';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import ReactMarkdown from 'react-markdown';
import { LOCALES } from '../../../const/locale';

import './style.scss';
import { minLength, required } from 'validators';

const minLength3 = minLength(3);

const NewsForm = ({ handleSubmit, type, editorValue }) => (
  <form className="auth-form" autoComplete="off">
    <Field
      component={ui.Fields.BaseField}
      name="commonId"
      maxLength={150}
      validate={minLength3}
      props={{
        id: 'commonId',
        label: 'Common ID',
        fullWidth: true,
      }}
    />
    <Field
      component={ui.Fields.BaseField}
      name="title"
      validate={required}
      props={{
        id: 'title',
        label: 'Title:',
        fullWidth: true,
      }}
    />
    <Field
      component={ui.Fields.BaseField}
      name="description"
      props={{
        id: 'description',
        label: 'Description:',
        fullWidth: true,
        maxLength: 200,
        multiline: true,
      }}
    />
    <Field
      component={ui.Fields.DropdownField}
      dropdownItems={LOCALES}
      styleWrapper={{ marginTop: '30px', marginBottom: '10px' }}
      style={{ marginBottom: '10px' }}
      name="locale"
      maxLength={150}
      validate={required}
      props={{
        id: 'locale',
        label: 'Locale',
        fullWidth: true,
      }}
      firstOptionLabel={'-'}
      disabled={type === 'save'}
    />
    <Field
      component={ui.Fields.ImageField}
      name="icon"
      label="Icon:"
      maxWidth={1000}
      maxHeight={1000}
      validate={required}
    />
    <Field component={ui.Fields.CheckboxField} name="isInternal" label="Is internal" />
    <Field
      component={ui.Fields.MarkdownField}
      name="body"
      minRows={4}
      maxRows={10}
      label="News body:"
    />
    <h1 style={{ margin: 20 }}>Preview:</h1>
    <ReactMarkdown source={editorValue} className="markdown" />
    <Button
      variant="outlined"
      onClick={handleSubmit}
      style={{
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      {type === 'create' ? 'CREATE' : 'SAVE'}
    </Button>
  </form>
);

NewsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  editorValue: PropTypes.string.isRequired,
};

const selector = formValueSelector('newsForm');

export default compose(
  connect((state) => ({
    editorValue: selector(state, 'body'),
  })),
  reduxForm({
    form: 'newsForm',
  }),
)(NewsForm);
