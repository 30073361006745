import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose, withState, lifecycle } from 'recompose';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import { forms, apiHOCs } from 'components';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import endpoints from 'api/endpoints';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form/immutable';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

const TermsScreen = ({
  terms,
  setModal,
  setSelected,
  setInitTerms,
  modal,
  initTerms,
  selected,
  postTerms,
  putTerm,
  lang,
  deleteTerms,
  deleteFullTerms,
}) => {
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [open, setOpen] = React.useState(false);
  const getFileInfo = async (key, lang) => {
    if (key && lang) {
      const response = await fetch(`${endpoints.getFileBucketUrl('terms')}/${key}-${lang}`, {
        cache: 'no-cache',
      });
      const reader = await response.body.getReader();
      const arrayBuffer = await reader.read();

      return new TextDecoder('utf-8').decode(arrayBuffer.value).replace(/\\n/g, '\n\n');
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openModalForEdit = async (term) => {
    if (term) {
      setSelectedTerm(term);
      setSelected(term.get('id'));
      setInitTerms({
        body: '',
        version: term.get('version'),
        lang: term.get('langs').toJS()[0],
      });
      setModal(true);
    }
  };

  useEffect(() => {
    if (selectedTerm && lang) {
      const getFileTerm = async () => {
        const fileTerms = await getFileInfo(selectedTerm.get('key'), lang);
        setInitTerms({
          body: fileTerms,
          version: selectedTerm.get('version'),
          lang: lang,
        });
      };
      getFileTerm();
    }
  }, [lang]);

  const isCreateTemrForCurrentLang = () => {
    return selectedTerm?.get('langs')?.includes(lang);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Paper
        elevation={1}
        style={{
          width: 800,
          padding: 10,
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        <Typography
          variant="headline"
          component="h3"
          style={{
            textAlign: 'center',
          }}
        >
          Terms
        </Typography>
      </Paper>
      <Paper
        elevation={1}
        style={{
          width: 800,
          padding: 10,
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        <List>
          {terms.map((term) => (
            <ListItem
              key={term.get('id')}
              button
              onClick={() => {
                openModalForEdit(term);
              }}
            >
              <ListItemText
                primary={`ID: ${term.get('id')}`}
                secondary={`Version: ${term.get('version')}, lang: ${term
                  .get('langs')
                  .toJS()}, createdAt: ${term.get('createdAt')}`}
              />
              <Button
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  openModalForEdit(term);
                }}
                color="primary"
                style={{
                  marginRight: 20,
                }}
              >
                EDIT
              </Button>
              <Button
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedTerm(term);
                  handleClickOpen();
                }}
                color="secondary"
              >
                DELETE
              </Button>
            </ListItem>
          ))}
        </List>
        <Button variant="contained" onClick={() => setModal(true)} color="primary" fullWidth>
          ADD NEW
        </Button>
      </Paper>
      <Modal
        open={modal}
        onClose={() => {
          setModal(false);
          setInitTerms({ version: '', lang: '', body: '' });
          setSelected('');
        }}
      >
        <Paper
          elevation={1}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: 700,
            height: '100%',
            overflow: 'auto',
            padding: 20,
            maxHeight: '70vh',
          }}
        >
          {modal && (
            <forms.TermsForm
              onSubmit={(body) =>
                selected === '' || !isCreateTemrForCurrentLang()
                  ? postTerms({ body: body.toJS() }).then((resp) => {
                      if (!resp.body.error) {
                        setModal(false);
                      } else {
                        alert('postTerms error');
                        console.log(JSON.stringify(resp.body.data));
                      }
                    })
                  : putTerm({
                      body: body.toJS(),
                      id: selected,
                      version: body.get('version'),
                      lang: lang,
                    }).then((resp) => {
                      if (!resp.body.error) {
                        setModal(false);
                      } else {
                        alert('putTopic error');
                        console.log(JSON.stringify(resp.body.data));
                      }
                    })
              }
              type={selected === '' ? 'create' : 'save'}
              initialValues={selected ? initTerms : {}}
              isCreateTemrForCurrentLang={isCreateTemrForCurrentLang()}
              deleteTerms={deleteTerms}
              setModal={setModal}
              selectedTerm={selectedTerm}
            />
          )}
        </Paper>
      </Modal>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title-termsForm">
          {'Are you sure you want to delete terms for all lang ?'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleClose();
              deleteFullTerms({ version: selectedTerm.get('version'), id: selectedTerm.get('id') });
              setModal(false);
            }}
            autoFocus
          >
            Confirm deletion
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

TermsScreen.propTypes = {
  terms: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
  setInitTerms: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
  initTerms: PropTypes.object.isRequired,
  selected: PropTypes.string.isRequired,
  postTerms: PropTypes.func.isRequired,
  putTerm: PropTypes.func.isRequired,
  deleteTerms: PropTypes.func.isRequired,
  deleteFullTerms: PropTypes.func.isRequired,
};

TermsScreen.defaultProps = {};
const selector = formValueSelector('TermsForm');

export default compose(
  connect((state) => ({
    lang: selector(state, 'lang'),
  })),
  withState('modal', 'setModal', false),
  withState('initTerms', 'setInitTerms', {}),
  withState('selected', 'setSelected', ''),
  apiHOCs.TermsApiHOC(),
  lifecycle({
    componentDidMount() {
      this.props.getTerms();
    },
  }),
)(TermsScreen);
