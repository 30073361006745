import { createSelector } from 'reselect';
import { getEntities, getResults } from 'reducers';
import Immutable from 'immutable';
import { denormalize } from 'normalizr';
import { news } from 'schemas';

export const getNews = createSelector(
  state => getResults(state).get('news', Immutable.List()),
  state => getEntities(state),
  (result, entities) =>
    denormalize(
      result,
      news.schemasArray,
      entities,
    ),
);

export const getNewsMeta = state => getResults(state).get('newsMeta', Immutable.List());
