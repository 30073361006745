import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  withHandlers,
  lifecycle,
  withState,
} from 'recompose';

import endpoints from "api/endpoints";
import {getToken} from "helpers/token";

import './style.scss';


const MultiImageField = ({
  input: { value },
  uploadImage,
  label,
  setImages,
  images,
  returnObjectString,
  meta: {
    touched,
    error,
  }
}) => (
  <div className="image-picker">
    <input
      id="file"
      type="file"
      multiple
      onChange={uploadImage}
      className="image-picker__input"
    />
    <label htmlFor="file">
      {`Choose a ${label}`}
    </label>
    {
      value && Array.isArray(value) && value.map(img => (
        <div className="image-picker__image-wrapper" key={img}>
          <button
            className="image-picker__image-wrapper__button"
            type="button"
            onClick={() => setImages(images.filter(image => image !== img))}
          >
            DEL
          </button>
          <img
            src={returnObjectString ? JSON.parse(img).link : img}
            alt={returnObjectString ? JSON.parse(img).name : 'icon'}
            className="image-picker__image"
          />
        </div>
      ))
    }
    {touched && error && <span className='field-error'>{error}</span>}
  </div>
);

MultiImageField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  uploadImage: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  setImages: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
  returnObjectString: PropTypes.bool,
};

MultiImageField.defaultProps = {
  returnObjectString: false,
};

export default compose(
  withState('images', 'setImages', []),

  withHandlers({
    uploadImage: ({ setImages, input: { value }, returnObjectString = false }) => async (e) => {

      const files = [];
      const loadedImages = [];

      Object.keys(e.target.files).forEach((key) => {
        files.push(e.target.files[key]);
      });

      for (const file of files) {
        const formData = new FormData();
        formData.append('file', file);

        const response = await fetch(endpoints.getUploadingBucketUrl('news'), {
          body: formData,
          method: 'POST',
          headers: {
            Authorization: getToken(),
          },
        });

        const data = await response.json();

        loadedImages.push(`${endpoints.getFileBucketUrl('news')}/${data.urls[0]}`);
      }

      setImages([...value, ...loadedImages])
      }
  }),

  lifecycle({
    componentDidMount() {
      if (this.props.initImages) {
        this.props.setImages(this.props.initImages.toJS());
      }
    },
    componentDidUpdate(prevProps) {
      if (prevProps.images !== this.props.images) {
        this.props.input.onChange(this.props.images);
      }
    },
  }),
)(MultiImageField);
