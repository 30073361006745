import React, {Suspense} from 'react';
import {Switch, Route} from 'react-router';
import moment from 'moment';
import ruLocale from 'moment/locale/ru';
import './i18next';

import {Authentication} from 'components';
import * as screens from 'screens';
import './style.scss';

moment.locale = ruLocale;

const App = () => (
  <div className="app-layout">
    <Suspense fallback="loading">
      <Authentication>
        <Switch>
          <Route path="/signin" component={screens.SignInScreen}/>
          <Route path="/" component={screens.HomeScreen}/>
        </Switch>
      </Authentication>
    </Suspense>
  </div>
);

export default App;
