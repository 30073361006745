import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import endpoints from 'api/endpoints';
import { requestAsync, querySelectors } from '@digitalwing.co/redux-query-immutable';
import { connect } from 'react-redux';
import { boards } from 'api';

import { getBoards } from './selectors';

const BoardsApiHOC = () => WrappedComponent => compose(
  connect(
    state => ({
      boards: getBoards(state),

      boardIsFetching: querySelectors.isPending(
        state.get('queries'),
        { queryKey: endpoints.getBoardsUrl() },
      ) || false,
      deleteStoryIsFetching: querySelectors.isPending(
        state.get('queries'),
        { queryKey: endpoints.getDeleteStoryUrl() },
      ) || false,
      createStoryIsFetching: querySelectors.isPending(
        state.get('queries'),
        { queryKey: `post${endpoints.getStoryUrl()}` },
      ) || false,
    }),
    dispatch => ({
      ...bindActionCreators({
        getBoards: () => requestAsync(
          boards.getBoards(),
        ),
        deleteBoard: id => requestAsync(
          boards.deleteBoard(id),
        ),
        postCreateBoard: ({ requestBody }) => requestAsync(
          boards.postCreateBoard({ requestBody }),
        ),
        putBoard: ({ requestBody }) => requestAsync(
          boards.putBoard({ requestBody }),
        ),
      }, dispatch),
    }),
  ),
)(WrappedComponent);

export default BoardsApiHOC;
