import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Field, reduxForm, formValueSelector } from 'redux-form/immutable';
import { ui } from 'components';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { required } from '../../../validators';

const BoardsForm = ({
  handleSubmit,
  type,
  dropdownItems,
  showParentId,
  invalid,
}) => (
  <form className="auth-form" autoComplete="off">
    <Field
      component={ui.Fields.CheckboxField}
      name="withParent"
      label="With parent"
      disabled={type !== 'create'}
    />
    {
      showParentId && (
        <Field
          component={ui.Fields.DropdownField}
          name="parentId"
          label="Select parent id"
          dropdownItems={dropdownItems}
          disabled={type !== 'create'}
        />
      )
    }
    <Field
      component={ui.Fields.BaseField}
      name="title"
      validate={required}
      props={{
        id: 'title',
        label: 'Title',
        fullWidth: true,
        maxLength: 150,
      }}
    />
    {
      showParentId && (
        <Field
          component={ui.Fields.BaseField}
          name="description"
          props={{
            id: 'description',
            label: 'Description',
            fullWidth: true,
            maxLength: 250,
          }}
        />
      )
    }
    {
      showParentId && (
        <Field
          component={ui.Fields.MarkdownField}
          name="rules"
          props={{
            id: 'rules',
            label: 'Rules:',
            fullWidth: true,
            maxLength: 2000,
          }}
          minRows={4}
          maxRows={10}
          styleWrapper={{
            marginTop: 20,
          }}
        />
      )
    }
    <Button
      variant="outlined"
      disabled={invalid}
      onClick={handleSubmit}
      style={{
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      {type === 'create' ? 'CREATE' : 'SAVE'}
    </Button>
  </form>
);

BoardsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  dropdownItems: PropTypes.array.isRequired,
  showParentId: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
};

const selector = formValueSelector('boardsForm');

export default compose(
  connect(state => ({
    showParentId: selector(state, 'withParent'),
  })),
  reduxForm({
    form: 'boardsForm',
  }),
)(BoardsForm);
