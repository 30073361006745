import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Field, reduxForm } from 'redux-form/immutable';
import { ui } from 'components';
import Button from '@material-ui/core/Button';
import { notEmptyArray } from 'validators'; 

const AddStoryForm = ({
  handleSubmit,
  type,
  dropdownItems,
  initialValues,
}) => (
  <form className="auth-form" autoComplete="off">
    <Field
      component={ui.Fields.DropdownField}
      name="newsId"
      label="Select news:"
      dropdownItems={dropdownItems}
    />
    <Field
      component={ui.Fields.MultiImageField}
      name="images"
      initImages={initialValues ? initialValues.get('images') : []}
      props={{
        id: 'icon',
        label: 'icon',
      }}
      validate={notEmptyArray}
    />
    <Button
      variant="outlined"
      onClick={handleSubmit}
      style={{
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      {type === 'create' ? 'CREATE' : 'SAVE'}
    </Button>
  </form>
);

AddStoryForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  dropdownItems: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
};

AddStoryForm.defaultProps = {
  initialValues: null,
};

export default compose(
  reduxForm({
    form: 'addStoryForm',
  }),
)(AddStoryForm);
