import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Field, formValueSelector, reduxForm } from 'redux-form/immutable';
import { ui } from 'components';
import Button from '@material-ui/core/Button';
import ReactMarkdown from "react-markdown";
import { connect } from "react-redux";
import { minLength } from 'validators';
import './style.scss';

const minLength6 = minLength(6);
const minLength3 = minLength(3);

const FaqArticleForm = ({
  handleSubmit,
  type,
  editorValue,
  dropdownItems,
  initialValues
}) => {
  
  return (
  <form className="auth-form" autoComplete="off">
    <Field
      component={ui.Fields.BaseField}
      name="commonId"
      maxLength={150}
      validate={minLength3}
      props={{
        id: 'commonId',
        label: 'Common ID',
        fullWidth: true,
      }}
    />
    <Field
      component={ui.Fields.BaseField}
      name="title"
      maxLength={150}
      validate={minLength6}
      props={{
        id: 'title',
        label: 'Title',
        fullWidth: true,
      }}
    />
    <Field
      component={ui.Fields.DropdownField}
      name="topicId"
      label="Select topic:"
      dropdownItems={dropdownItems}
      disabled={true}
      styleWrapper={{ marginTop: 20 }}
      input={{
        onChange: ()=> {},
        value: initialValues.get('topicId'),
      }}
    />
    <Field
      component={ui.Fields.MarkdownField}
      name="body"
      minRows={4}
      maxRows={10}
      label="News body:"
      styleWrapper={{ marginTop: 20 }}
    />
    <h1 style={{ margin: 20 }}>
      Preview:
    </h1>
    <ReactMarkdown source={editorValue} className="markdown" />
    <Button
      variant="outlined"
      onClick={handleSubmit}
      style={{
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      {type === 'create' ? 'CREATE' : 'SAVE'}
    </Button>
  </form>
)};

FaqArticleForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  editorValue: PropTypes.string.isRequired,
  dropdownItems: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
};

const selector = formValueSelector('faqArticleForm');

export default compose(
  connect(state => ({
    editorValue: selector(state, 'body'),
  })),
  reduxForm({
    form: 'faqArticleForm',
  }),
)(FaqArticleForm);
