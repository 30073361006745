import endpoints from 'api/endpoints';
import { normalize } from 'normalizr';
import { board } from 'schemas';
import Immutable from 'immutable';

export default ({ requestBody }) => ({
  url: endpoints.getBoardsUrl(),
  queryKey: `post${endpoints.getBoardsUrl()}`,
  transform: response => normalize(response.data, board.schema).entities,
  transformResult: response => ({
    boards: normalize(response.data, board.schema).result,
  }),
  body: requestBody,
  force: true,
  meta: {
    authToken: true,
  },
  options: {
    headers: {
      Accept: 'application/json',
    },
    method: 'POST',
  },
  update: {
    board: (prevEntities = Immutable.Map(), nextEntities) =>
      prevEntities.mergeDeep(nextEntities),
  },
  updateResult: {
    boards: (prevResult = Immutable.List(), result) => prevResult.push(result),
  },
});
