import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Button from '@material-ui/core/Button';

import './style.scss';

const UserProfile = ({
  bannedUntil,
  lastName,
  isBlocked,
  cryptoPriority,
  lastBannedAt,
  is2faActive,
  basicExchange,
  safety,
  lang,
  banReason,
  bannedBy,
  avatar,
  username,
  role,
  rating,
  online,
  registrationDate,
  isConfirm,
  language,
  firstName,
  selectedCrypto,
  email,
  description,
  basicCurrency,
  handleSubmit,
}) => {
  const dataObj = {
    basic: [
      {
        title: 'Description',
        value: description,
      },
      {
        title: 'Registration Date',
        value: moment(registrationDate).format('D MMMM YYYY'),
      },
      {
        title: 'Rating',
        value: rating,
      },
      {
        title: 'Language',
        value: language,
      },
      {
        title: 'Supported languages',
        value: lang,
      },
      {
        title: 'Confirm status',
        value: isConfirm,
      },
      {
        title: '2FA',
        value: is2faActive,
      },
      {
        title: 'Last block',
        value: lastBannedAt,
      },
      {
        title: 'PIN',
        value: safety.get('isPincodeSet'),
      },
    ],
    money: [
      {
        title: 'Basic currency',
        value: basicCurrency,
      },
      {
        title: 'Selected crypto',
        value: selectedCrypto,
      },
      {
        title: 'Crypto priority',
        value: cryptoPriority,
      },
      {
        title: 'Basic exchange',
        value: basicExchange,
      },
    ],
    block: [
      {
        title: 'Blocked by',
        value: bannedBy,
      },
      {
        title: 'Blocked reason',
        value: banReason,
      },
      {
        title: 'Blocked until',
        value: bannedUntil,
      },
    ],
  };

  return (
    <div className="user-profile">
      <div className="user-profile__header">
        <div
          className="user-profile__header__avatar"
          style={{
            backgroundImage: `url(${avatar})`,
            borderColor: online ? 'green' : 'grey',
          }}
        />
        <div className="user-profile__header__info">
          {lastName && firstName && (
            <span>{`${firstName} ${lastName}`}</span>
          )}
          <span>{`Nickname: ${username}`}</span>
          <span>{`Email: ${email}`}</span>
          <span>{`Role: ${role}`}</span>
          {!!online && (
            <span>User is online now</span>
          )}
        </div>
      </div>

      <div className="user-profile__content">
        <div className="user-profile__content__subblock">
          <h3>Basic information:</h3>
          {dataObj.basic.map(obj => !!obj.value && (
            <span
              key={obj.title}
              className="user-profile__content__subblock__element"
            >
              {`${obj.title}: ${obj.value}`}
            </span>
          ))}
        </div>

        <div className="user-profile__content__subblock">
          <h3>Money:</h3>
          {dataObj.money.map(obj => !!obj.value && (
            <span
              key={obj.title}
              className="user-profile__content__subblock__element"
            >
              {`${obj.title}: ${obj.value}`}
            </span>
          ))}
        </div>

        {isBlocked && (
          <div className="user-profile__content__subblock">
            <h3>Block status:</h3>
            {dataObj.block.map(obj => !!obj.value && (
              <span
                key={obj.title}
                className="user-profile__content__subblock__element"
              >
                {`${obj.title}: ${obj.value}`}
              </span>
            ))}
          </div>
        )}
      </div>

      <Button
        className="user-profile__block-button"
        variant="contained"
        color="secondary"
        onClick={handleSubmit}
      >
        {isBlocked ? 'UNBAN' : 'BAN'}
      </Button>
    </div>
  );
};

UserProfile.propTypes = {
  bannedUntil: PropTypes.string,
  lastName: PropTypes.string.isRequired,
  isBlocked: PropTypes.bool.isRequired,
  cryptoPriority: PropTypes.string,
  lastBannedAt: PropTypes.string.isRequired,
  is2faActive: PropTypes.bool.isRequired,
  basicExchange: PropTypes.string.isRequired,
  safety: PropTypes.object.isRequired,
  lang: PropTypes.object.isRequired,
  banReason: PropTypes.string.isRequired,
  bannedBy: PropTypes.string.isRequired,
  online: PropTypes.number.isRequired,
  isConfirm: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  selectedCrypto: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  basicCurrency: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  registrationDate: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

UserProfile.defaultProps = {
  bannedUntil: '',
  cryptoPriority: '',
};

export default UserProfile;
