import getPosts from './getPosts';
import deletePost from './deletePost';
import postCreatePost from './postCreatePost';
import putPost from './putPost';

export default {
  getPosts,
  deletePost,
  postCreatePost,
  putPost,
};
