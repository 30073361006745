import endpoints from 'api/endpoints';

export default ({ id, topicId }) => ({
  url: endpoints.getFaqArticleByIdUrl(id),
  queryKey: `delete${endpoints.getFaqArticleByIdUrl()}`,
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: 'application/json',
    },
    method: 'DELETE',
  },
  update: {
    topicFaq: prevEntities => prevEntities.updateIn([topicId, 'articles'], articles => articles.filter(item => (item.get('id') || item.get('id')) !== id)),
  },
});
