import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  withHandlers,
  getContext,
  branch,
  renderComponent,
  withProps,
} from 'recompose';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { forms, apiHOCs } from 'components';
import { Redirect } from 'react-router';
import { token } from 'helpers';
import _ from 'lodash';

import './style.scss';

const SignInScreen = ({ login }) => (
  <div className="signin-layout">
    <Paper
      elevation={10}
      style={{
        width: 300,
        height: 400,
        padding: 30,
      }}
    >
      <Typography
        variant="headline"
        component="h3"
        style={{
          textAlign: 'center',
          marginTop: 20,
          marginBottom: 40,
        }}
      >
        Site Admin
      </Typography>
      <Typography component="p">
        <forms.AuthForm
          onSubmit={login}
        />
      </Typography>
    </Paper>
  </div>
);

SignInScreen.propTypes = {
  login: PropTypes.func.isRequired,
};

const redirectComponent = withProps({ to: '/' })(Redirect);

export default compose(
  apiHOCs.AuthApiHOC(),

  branch(
    ({ user }) => (user && user.size && user.get('accessRights') === 1022),
    renderComponent(redirectComponent),
  ),

  getContext({
    router: PropTypes.shape({
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
      }).isRequired,
    }).isRequired,
  }),

  withHandlers({
    login: ({ getUser, postLogin, router }) => formData =>
      postLogin({
        requestBody: {
          email: formData.get('email'),
          password: formData.get('password'),
          captcha: formData.get('captcha'),
          remember: true,
        },
      })
        .then((res) => {
          const authToken = _.get(res, 'body.data.token', '');
          const role = _.get(res, 'body.data.role', '');
          if (authToken) {
            if (role === 'moderator' || role === 'admin') {
              token.setToken(authToken);
              getUser()
                .then(() => router.history.push('/'));
            } else {
              alert('You are not admin!');
            }
          } else {
            alert('Wrong email/password!');
          }
        }),
  }),
)(SignInScreen);
