import React from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers, withStateHandlers } from 'recompose';
import Textarea from 'react-textarea-autosize';
import boldIcon from 'assets/img/bold.svg';
import italicIcon from 'assets/img/italic.svg';
import underlineIcon from 'assets/img/underline.svg';
import './style.scss';

const buttons = [
  {
    type: 'bold',
    openTag: '**',
    closeTag: '**',
    icon: boldIcon,
  },
  {
    type: 'italic',
    openTag: '*',
    closeTag: '*',
    icon: italicIcon,
  },
  {
    type: 'underline',
    openTag: '**_',
    closeTag: '_**',
    icon: underlineIcon,
  },
];

const refInput = React.createRef();

const MarkdownField = ({
  input,
  styleWrapper,
  styleWrapperContent,
  handleChange,
  input: {
    value,
  },
  meta: {
    active,
    touched,
    error,
  },
  minRows,
  maxRows,
  innerStyle,
  label,
  disabled,
  maxLength,
  selectTag,
  onBlur,
}) => (
  <div
    style={styleWrapper}
    className={`textarea-field${!active && touched && error ? ' textarea-field_error' : ''}`}
  >
    {
      label && (
        <label
          className="textarea-field__label"
          htmlFor={input.name}
        >
          {label}
        </label>
      )
    }
    <div
      style={styleWrapperContent}
      className={`textarea-field__wrapper${disabled ? ' textarea-field__wrapper_disabled' : ''}`}
    >
      <div className="textarea-field__wrapper__header">
        {
          buttons.map(B => (
            <button
              className="textarea-field__wrapper__header__button"
              type="button"
              key={B.type}
              onClick={() => selectTag(B)}
            >
              <img
                className="textarea-field__wrapper__header__icon"
                src={B.icon}
                alt={B.type}
              />
            </button>
          ))
        }
      </div>
      <Textarea
        {...input}
        id={input.name}
        value={value}
        style={innerStyle}
        onChange={handleChange}
        minRows={minRows}
        maxRows={maxRows}
        disabled={disabled}
        maxLength={maxLength}
        inputRef={refInput}
        onBlur={onBlur}
      />
    </div>
    {
      !active && touched && error && (
        <div className="textarea-field__error-text">
          {error}
        </div>
      )
    }
  </div>
);

MarkdownField.propTypes = {
  handleChange: PropTypes.func.isRequired,
  styleWrapper: PropTypes.any,
  styleWrapperContent: PropTypes.any,
  input: PropTypes.shape({
    value: PropTypes.any.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    valid: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }),
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  label: PropTypes.string,
  innerStyle: PropTypes.any,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  selectTag: PropTypes.func.isRequired,
};

MarkdownField.defaultProps = {
  meta: {
    error: '',
  },
  styleWrapper: {},
  styleWrapperContent: {},
  innerStyle: {},
  minRows: 1,
  maxRows: 8,
  label: '',
  disabled: false,
  maxLength: 524288,
};

export default compose(
  withStateHandlers({}, {
    onBlur: () => ({ target }) => ({
      selectionStart: target.selectionStart,
      selectionEnd: target.selectionEnd,
      selectionLength: target.selectionEnd - target.selectionStart,
    }),
  }),
  withHandlers({
    handleChange: ({ input: { onChange, value } }) => (evt) => {
      onChange(evt.target.validity.valid ? evt.target.value : value);
    },
    selectTag: ({
      input: { onChange, value },
      selectionStart,
      selectionEnd,
      selectionLength,
    }) => ({ openTag, closeTag }) => {
      // if (!selectionStart && !selectionEnd) {
      // }
      const result = `${value.substr(0, selectionStart)}${openTag}${value.substr(selectionStart, selectionLength)}${closeTag}${value.substr(selectionEnd)}`;
      onChange(result);
    },
  }),
)(MarkdownField);
