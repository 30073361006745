import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const DateTimeField = ({
  id,
  label,
  input: { value, onChange },
  disabled,
}) => (
  <TextField
    id={id}
    value={value}
    onChange={onChange}
    disabled={disabled}
    label={label}
    type="datetime-local"
    margin="normal"
    InputLabelProps={{
      shrink: true,
    }}
  />
);

DateTimeField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
};

DateTimeField.defaultProps = {
  disabled: false,
};

export default DateTimeField;
