import React from 'react';
import PropTypes from 'prop-types';
import { Circle } from 'better-react-spinkit';

import './style.scss';

const LoadingSpinner = ({
  fullSize,
  style,
  circleProps,
  small,
}) => (
  <div className="spinner-outer" style={{ height: fullSize ? '100vh' : 'auto', ...style }}>
    <Circle
      scaleStart={0.4}
      scaleEnd={0.7}
      size={small ? 30 : 100}
      color="#4A4A4A"
      {...circleProps}
    />
  </div>
);

LoadingSpinner.propTypes = {
  fullSize: PropTypes.bool,
  style: PropTypes.object,
  circleProps: PropTypes.object,
  small: PropTypes.bool,
};

LoadingSpinner.defaultProps = {
  fullSize: false,
  style: {},
  circleProps: {},
  small: false,
};

export default LoadingSpinner;
