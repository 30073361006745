import { createSelector } from 'reselect';
import { getEntities, getResults } from 'reducers';
import Immutable from 'immutable';
import { denormalize } from 'normalizr';
import { topic } from 'schemas';

export const getTopics = createSelector(
  state => getResults(state).get('topics', Immutable.List()),
  state => getEntities(state),
  (result, entities) =>
    denormalize(
      result,
      topic.schemasArray,
      entities,
    ),
);

export const getTopicsMeta = state => getResults(state).get('topicsMeta', Immutable.List());
