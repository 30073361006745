import React, {useEffect} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(3),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function LangDropdown({onChangeLang, label}) {
  const classes = useStyles();
  const [lang, setLang] = React.useState('ru');

  const handleChange = (event) => {
    setLang(event.target.value);
  };

  useEffect(() => {
    onChangeLang(lang);
  }, [lang, onChangeLang])

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-label">Язык</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={lang}
        onChange={handleChange}
      >
        <MenuItem value={'ru'}>Рус</MenuItem>
        <MenuItem value={'en'}>Eng</MenuItem>
      </Select>
    </FormControl>
  );
}

export default LangDropdown;
