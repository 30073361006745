import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from "@material-ui/core/styles";

import endpoints from '../../api/endpoints';
import JsonTree from "../../components/ui/jsonTree";
import LangDropdown from '../../components/ui/langDropdown';


const useStyles = makeStyles({
  root: {
    width: '95%',
    maxWidth: '1000px',
    margin: '0 auto',
    padding: '35px 25px',
  }
})

function TranslationsTreeScreen() {
  const {t, i18n} = useTranslation();
  const [lang, setLang] = useState('ru');
  const classes = useStyles();

  // eslint-disable-next-line no-shadow
  const handleLangChange = (lang) => {
    setLang(lang);
  };

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);


  const handleTranslationUpdate = ({updated_src, new_value, existing_value, namespace, name}) => {

    if(existing_value.trim() !== new_value.trim()){
      const tree_node = namespace.join('.') + '.' + name;
      const url = endpoints.getPutTranslationsUrl();
      const options = {
        stringify: data => JSON.stringify(data),
      };
      const body = { lang, tree_node, new_value };
      const callback = (err) => {
        if (err) throw new Error(err);
        i18n.reloadResources(i18n.languages, 'translation');
      };

      i18n.options.backend.request(options, url, body, callback);
    }
  };


  return (
    <div className={classes.root}>
      <LangDropdown onChangeLang={handleLangChange}/>
      <JsonTree
        data={i18n.getResourceBundle(lang, 'translation')}
        handleUpdate={handleTranslationUpdate}
      />
    </div>
  );
}

export default TranslationsTreeScreen;
