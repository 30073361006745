import React from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import './style.scss';


const captchaSitekey = process.env.REACT_APP_CAPTCHA_KEY_SITE;

const ReCAPTCHAField = ({
  input: {
    name,
    onChange,
  },
  input,
  meta: {
    active,
    touched,
    error,
  },
  styleWrapper,
  label,
  reCaptchaRef,
}) => (
  <div
    style={styleWrapper}
    className={`re-captcha-field ${!active && touched && error ? 're-captcha-field_error' : ''}`}
  >
    {
      label && (
        <label
          className="re-captcha-field__label"
          htmlFor={name}
        >
          {label}
        </label>
      )
    }
    <div className="re-captcha-field__wrapper-input">
      <ReCAPTCHA
        {...input}
        ref={reCaptchaRef}
        sitekey={captchaSitekey}
        onChange={onChange}
      />
    </div>
    {
      !active && touched && error && (
        <div className="re-captcha-field__error-text">
          {error}
        </div>
      )
    }
  </div>
);

ReCAPTCHAField.propTypes = {
  label: PropTypes.string,
  styleWrapper: PropTypes.any,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    valid: PropTypes.bool,
    error: PropTypes.string,
  }),
  reCaptchaRef: PropTypes.any.isRequired,
};

ReCAPTCHAField.defaultProps = {
  meta: {
    error: '',
  },
  label: '',
  styleWrapper: null,
};

export default ReCAPTCHAField;
