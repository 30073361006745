import endpoints from 'api/endpoints';
import { normalize } from 'normalizr';
import { post } from 'schemas';
import Immutable from 'immutable';

export default ({ requestBody }) => ({
  url: endpoints.getPostsUrl(),
  queryKey: `post${endpoints.getPostsUrl()}`,
  transform: response => normalize(response.data, post.schema).entities,
  transformResult: response => ({
    posts: normalize(response.data, post.schema).result,
  }),
  body: requestBody,
  force: true,
  meta: {
    authToken: true,
  },
  options: {
    headers: {
      Accept: 'application/json',
    },
    method: 'POST',
  },
  update: {
    post: (prevEntities = Immutable.Map(), nextEntities) =>
      prevEntities.mergeDeep(nextEntities),
  },
  updateResult: {
    posts: (prevResult = Immutable.List(), result) => prevResult.push(result),
  },
});
