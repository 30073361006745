import endpoints from 'api/endpoints';
import { fromJS } from 'immutable';

export default ({ version, lang, id }) => ({
  url: endpoints.deleteTermsUrl(version, lang, id),
  queryKey: `delete${endpoints.deleteTermsUrl()}`,
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: 'application/json',
    },
    method: 'DELETE',
  },
  update: {
    term: (prevEntities) => prevEntities.filter((item) => item.get('id') !== id),
  },
  updateResult: {
    terms: (prevResult) => {
      const backToObj = prevResult.toJS();
      const currentIndex = backToObj.findIndex((item) => item.id === id)
      if (backToObj[currentIndex].langs?.length > 1) {
        backToObj[currentIndex] = {...backToObj[currentIndex], 'langs': backToObj[currentIndex].langs.filter((itemLang)=> itemLang !== lang) }
        const immutableObj = fromJS(backToObj)
        return immutableObj;
      }
      return prevResult.filter((item)=> item.get('id') !== id);
    },
  },
});
