import endpoints from 'api/endpoints';
import { normalize } from 'normalizr';
import { news } from 'schemas';
import Immutable from 'immutable';

export default ({ requestBody }) => ({
  url: endpoints.getSingleNewsUrl(requestBody.id),
  queryKey: `put${endpoints.getSingleNewsUrl()}`,
  transform: response => normalize(response.data, news.schema).entities,
  body: requestBody,
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: 'application/json',
    },
    method: 'PUT',
  },
  update: {
    newsItem: (prevStory = Immutable.Map(), nextStory) => prevStory.mergeDeep(nextStory),
  },
});
