import getTopics from './getTopics';
import deleteTopic from './deleteTopic';
import postCreateTopic from './postCreateTopic';
import putTopic from './putTopic';

export default {
  getTopics,
  deleteTopic,
  postCreateTopic,
  putTopic,
};
