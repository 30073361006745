import endpoints from 'api/endpoints';
import Immutable from 'immutable';

export default () => ({
  url: endpoints.getLogOutUrl(),
  queryKey: endpoints.getLogOutUrl(),
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: 'application/json',
    },
    method: 'POST',
  },
  update: {
    user: () => Immutable.Map(),
  },
  updateResult: {
    user: () => '',
  },
});
