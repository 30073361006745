import AuthApiHOC from './AuthApiHOC';
import StoriesApiHOC from './StoriesApiHOC';
import NewsApiHOC from './NewsApiHOC';
import UsersApiHOC from './UsersApiHOC';
import BoardsApiHOC from './BoardsApiHOC';
import FaqApiHOC from './FaqApiHOC';
import TopicsApiHOC from './TopicsApiHOC';
import PostsApiHOC from './PostsApiHOC';
import TermsApiHOC from './TermsApiHOC'

export default {
  AuthApiHOC,
  StoriesApiHOC,
  NewsApiHOC,
  UsersApiHOC,
  BoardsApiHOC,
  FaqApiHOC,
  TopicsApiHOC,
  PostsApiHOC,
  TermsApiHOC,
};
