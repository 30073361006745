import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import endpoints from 'api/endpoints';
import { requestAsync, querySelectors } from '@digitalwing.co/redux-query-immutable';
import { connect } from 'react-redux';
import { posts } from 'api';

import { getPosts, getPostsMeta } from './selectors';

const PostsApiHOC = () => WrappedComponent => compose(
  connect(
    state => ({
      posts: getPosts(state),
      postsMeta: getPostsMeta(state),

      postsIsFetching: querySelectors.isPending(
        state.get('queries'),
        { queryKey: endpoints.getSingleTopicUrl() },
      ) || false,
    }),
    dispatch => ({
      ...bindActionCreators({
        getPosts: (id, params) => requestAsync(
          posts.getPosts(id, params),
        ),
        deletePost: id => requestAsync(
          posts.deletePost(id),
        ),
        postCreatePost: ({ requestBody }) => requestAsync(
          posts.postCreatePost({ requestBody }),
        ),
        putPost: ({ requestBody }) => requestAsync(
          posts.putPost({ requestBody }),
        ),
      }, dispatch),
    }),
  ),
)(WrappedComponent);

export default PostsApiHOC;
